import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { serverUrl } from "../../App";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Header from "./Header";
import { ToastContainer } from "react-toastify";
import { ClipLoader } from "react-spinners";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faArrowCircleDown,
  faExclamationTriangle,
  faArrowUp,
  faChevronLeft,
  faChevronRight,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import Notfound from "../../Assets/NoResultFound.png";
import { vehicleAndWheelBackupFilePath } from "../../App";

const DataManagement = () => {
  const navigate = useNavigate();
  const [selectedDropdownOuId, setSelectedDropdownOuId] = useState();
  const [ouOptions, setOuOptions] = useState([]);
  const [showPurgePopup, setShowPurgePopup] = useState(false);
  const [showBackupDownloadPopup, setShowBackupDownloadPopup] = useState(false);
  const [showReconfirmPurgePopup, setShowReconfirmPurgePopup] = useState(false);
  const [showPurgingPopup, setShowPurgingPopup] = useState(false);
  const [dateFilter, setDateFilter] = useState("");
  const [totalTestsCount, setTotalTestsCount] = useState(0);
  const [totalDatabaseSize, setTotalDatabaseSize] = useState(0);
  const [totalVehicleWithWheelDBSize, setTotalVehicleWithWheelDBSize] =
    useState(0);
  const [isDeletingData, setIsDeletingData] = useState(true);
  const [isTakingBackup, setIsTakingBackup] = useState(true);
  const [purgeArchiveData, setPurgeArchiveData] = useState([]);
  const [purgeArchiveCount, setPurgeArchiveCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Add current page state
  const itemsPerPage = 50; // Set the number of items per page
  const [totalPages, setTotalPages] = useState(0); // Track total pages
  const startingSerialNumber = (currentPage - 1) * itemsPerPage + 1;

  // Calculate the range of records being displayed
  const rangeStart = (currentPage - 1) * itemsPerPage + 1;
  const rangeEnd = Math.min(
    rangeStart + purgeArchiveData.length - 1,
    currentPage * itemsPerPage
  );

  const [isLoadingListData, setIsLoadingListData] = useState(true);
  const [sortBy, setSortBy] = useState("createdDate");
  const [reverse, setReverse] = useState(false);
  const hasToastShownRef = useRef(false);
  const [isBlurEffectEnable, setIsBlurEffectEnable] = useState(false);
  const [purgeArchiveId, setPurgeArchiveId] = useState("");
  const [
    isPurgeArchiveDeleteConfirmationOpen,
    setPurgeArchiveDeleteConfirmationOpen,
  ] = useState(false);
  const [
    isPurgeArchiveDeleteReConfirmationOpen,
    setPurgeArchiveDeleteReConfirmationOpen,
  ] = useState(false);
  const [deletePurgeArchiveData, setDeletePurgeArchiveData] = useState(null);
  const [showBackupRestoreHelpModal, setShowBackupRestoreHelpModal] =
    useState(false);

  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };

  const handlePurgeClick = () => {
    setShowPurgePopup(true);
  };

  const handleBackupDownload = () => {
    setIsBlurEffectEnable(true);
    navigate(".", { state: { isBlurEffectEnable: true } });
    setShowBackupDownloadPopup(true);
    setShowPurgePopup(false);
    downloadVehicleTestBackup();
  };

  const handlePurgingclick = () => {
    setIsBlurEffectEnable(true);
    navigate(".", { state: { isBlurEffectEnable: true } });
    setShowReconfirmPurgePopup(false);
    setShowPurgingPopup(true);
    confirmDelete();
  };

  const handleClosePopup = () => {
    setShowPurgePopup(false);
    setShowBackupDownloadPopup(false);
    setShowReconfirmPurgePopup(false);
    setShowPurgingPopup(false);
  };

  // Success Notification
  const toastSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "green" },
    });
  };

  // Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  const handleApplyButtonClick = () => {
    fetchTotalTestsCount();
    fetchVehicleAndWheelTableSize();
  };

  const handlePagination = async (direction) => {
    let newPage;
    if (direction === "prev" && currentPage > 1) {
      newPage = currentPage - 1;
    } else if (direction === "next" && currentPage < totalPages) {
      newPage = currentPage + 1;
    } else {
      return;
    }
    setCurrentPage(newPage);
  };

  const handleSort = (newSortBy) => {
    setReverse((prevReverse) => !prevReverse); // Toggle the current state
    setSortBy(newSortBy); // Update sortBy
    setCurrentPage(1); // Always reset to the first page when sorting changes
  };

  const handleNoResultsFound = () => {
    if (currentPage === 1 && !hasToastShownRef.current) {
      hasToastShownRef.current = true;
      toastWarning("No Purge Archive Found", 3000);
      setPurgeArchiveData([]);
    }
    setPurgeArchiveCount(null);
    setTotalPages(0);
  };

  // Get Data From Session
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");

        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);
          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            if (parsedUserData?.entity?.roleOuObjs?.length > 0) {
              const uniqueOUs = parsedUserData.entity.roleOuObjs.map(
                (role) => ({
                  id: role.ouMasterObj.id,
                  ouName: role.ouMasterObj.ouName,
                })
              );
              const uniqueOUsFiltered = uniqueOUs.filter(
                (ou, index, self) =>
                  index ===
                  self.findIndex(
                    (t) => t.id === ou.id && t.ouName === ou.ouName
                  )
              );
              setOuOptions(uniqueOUsFiltered);
            }
          } else {
            toastWarning("User data not available.", 3000);
            return;
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };
    fetchData();
  }, [navigate]);

  useEffect(() => {
    fetchTotalDBSize();
  }, []);

  useEffect(() => {
    const fetchPurgeArchiveListData = async () => {
      await fetchPurgeArchiveTableData(currentPage);
    };
    fetchPurgeArchiveListData();
  }, [currentPage, sortBy, reverse]);

  useEffect(() => {
    if (purgeArchiveData.length > 0) {
      hasToastShownRef.current = false;
    }
  }, [purgeArchiveData]);

  const fetchTotalTestsCount = async () => {
    try {
      const response = await fetch(serverUrl + "vehicle/totalTestsCountInOu", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: selectedDropdownOuId,
          dateRange: dateFilter,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (
          data.status === 200 &&
          data.entity !== null &&
          data.entity !== undefined
        ) {
          setTotalTestsCount(data.entity);
        } else if (data.errors != null) {
          let errorDiscription = data.errors.errorDescription;
          toastWarning(errorDiscription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);
          return;
        }
      } else {
        console.error("Error fetching total vehicle tests count data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchTotalDBSize = async () => {
    try {
      const response = await fetch(serverUrl + "db/size", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });

      if (response.ok) {
        const data = await response.json();
        if (
          data.status === 200 &&
          data.entity !== null &&
          data.entity !== undefined
        ) {
          setTotalDatabaseSize(data.entity);
        } else if (data.errors != null) {
          let errorDiscription = data.errors.errorDescription;
          toastWarning(errorDiscription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);
          return;
        }
      } else {
        console.error("Error fetching total database size");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchVehicleAndWheelTableSize = async () => {
    try {
      const response = await fetch(
        serverUrl + "table/size/vehicleAndWheelTest",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ouMasterId: selectedDropdownOuId,
            dateRange: dateFilter,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (
          data.status === 200 &&
          data.entity !== null &&
          data.entity !== undefined
        ) {
          setTotalVehicleWithWheelDBSize(data.entity);
        } else if (data.errors != null) {
          let errorDiscription = data.errors.errorDescription;
          toastWarning(errorDiscription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);
          return;
        }
      } else {
        console.error("Error fetching total vehicle with wheel size");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const downloadVehicleTestBackup = async () => {
  //   setIsTakingBackup(true);
  //   try {
  //     const response = await fetch(serverUrl + "vehicleAndWheel/backup", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         ouMasterId: selectedDropdownOuId,
  //         dateRange: dateFilter,
  //       }),
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       if (data.status === 200 && data.entity != null) {
  //         // Open the backup in a new tab
  //         let exportFile = vehicleAndWheelBackupFilePath + data.entity.fileName;
  //         window.open(exportFile);
  //         setPurgeArchiveId(data.entity.purgeArchiveId);
  //         toastSuccess("Backup downloaded successfully");
  //         setIsTakingBackup(false);
  //         setShowBackupDownloadPopup(false);
  //         setIsBlurEffectEnable(false);
  //         navigate(".", { state: { isBlurEffectEnable: false } });
  //         fetchPurgeArchiveTableData();
  //         setShowReconfirmPurgePopup(true);
  //       } else if (data.errors != null) {
  //         let errorDiscription = data.errors.errorDescription;
  //         setIsTakingBackup(false);
  //         setIsBlurEffectEnable(false);
  //         navigate(".", { state: { isBlurEffectEnable: false } });
  //         setShowBackupDownloadPopup(false);
  //         toastWarning(errorDiscription, 3000);
  //         return;
  //       } else {
  //         toastWarning("Something Went Wrong", 3000);
  //         return;
  //       }
  //     } else {
  //       console.error("Error exporting backup");
  //       toastWarning("Error exporting backup", 3000);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const downloadVehicleTestBackup = async () => {
    setIsTakingBackup(true);
    try {
      const response = await fetch(serverUrl + "vehicleAndWheel/backup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: selectedDropdownOuId,
          dateRange: dateFilter,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.backupId) {
          // Poll for backup status
          await pollBackupStatus(data.backupId);
        } else {
          toastWarning("Backup request failed", 3000);
          setIsTakingBackup(false);
        }
      } else {
        console.error("Error exporting backup");
        toastWarning("Error exporting backup", 3000);
        setIsTakingBackup(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toastWarning("Something went wrong", 3000);
      setIsTakingBackup(false);
    }
  };

  // Polling function to check backup status
  const pollBackupStatus = async (backupId) => {
    let attempts = 0;
    let interval = 5000; // Start with 5 seconds

    console.log("BackupId:", backupId);

    const checkStatus = async () => {
      try {
        const response = await fetch(
          `${serverUrl}vehicleAndWheel/backup/status?backupId=${backupId}`
        );

        if (response.ok) {
          const data = await response.json();
          console.log(`Attempt ${attempts + 1}: Status - ${data.status}`);

          if (data.status === "COMPLETED") {
            console.log("Backup completed successfully!");
            // setIsTakingBackup(false);
            if (data) {
              // Open the backup in a new tab
              let exportFile = vehicleAndWheelBackupFilePath + data.fileName;
              window.open(exportFile);
              setPurgeArchiveId(data?.data?.purgeArchiveId);
              toastSuccess("Backup downloaded successfully");
              setIsTakingBackup(false);
              setShowBackupDownloadPopup(false);
              setIsBlurEffectEnable(false);
              navigate(".", { state: { isBlurEffectEnable: false } });
              fetchPurgeArchiveTableData();
              setShowReconfirmPurgePopup(true);
            }
            return;
          } else if (data.status === "FAILED") {
            console.error("Backup failed!");
            let errorDiscription = data.errors.errorDescription;
            setIsTakingBackup(false);
            setIsBlurEffectEnable(false);
            navigate(".", { state: { isBlurEffectEnable: false } });
            setShowBackupDownloadPopup(false);
            toastWarning(errorDiscription, 3000);
            return;
          }
        }
      } catch (error) {
        console.error("Polling error:", error);
      }

      attempts++;

      // Increase the interval to reduce load on the server (Max 30 seconds)
      interval = Math.min(interval + 5000, 30000);

      setTimeout(checkStatus, interval);
    };

    checkStatus();
  };

  // const confirmDelete = async () => {
  //   setIsDeletingData(true);
  //   try {
  //     const response = await fetch(
  //       serverUrl + "vehicleAndWheel/purge/hardDelete",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           ouMasterId: selectedDropdownOuId,
  //           dateRange: dateFilter,
  //           purgeArchiveId: purgeArchiveId,
  //         }),
  //       }
  //     );

  //     if (response.ok) {
  //       const data = await response.json();
  //       if (data.status === 200 && data.entity === "Successfully Deleted") {
  //         setIsDeletingData(false);
  //         setIsBlurEffectEnable(false);
  //         navigate(".", { state: { isBlurEffectEnable: false } });
  //         setShowPurgingPopup(false);
  //         toastSuccess("Successfully Deleted");
  //         fetchTotalTestsCount();
  //         fetchTotalDBSize();
  //         fetchVehicleAndWheelTableSize();
  //         fetchPurgeArchiveTableData();
  //       } else if (data.errors != null) {
  //         let errorDescription = data.errors.errorDescription;
  //         setIsDeletingData(false);
  //         setIsBlurEffectEnable(false);
  //         navigate(".", { state: { isBlurEffectEnable: false } });
  //         setShowPurgingPopup(false);
  //         toastWarning(errorDescription, 3000);
  //         return;
  //       } else {
  //         toastWarning("Something Went Wrong", 3000);
  //         return;
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const confirmDelete = async () => {
    setIsDeletingData(true);
    try {
      const response = await fetch(
        serverUrl + "vehicleAndWheel/purge/hardDelete",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ouMasterId: selectedDropdownOuId,
            dateRange: dateFilter,
            purgeArchiveId: purgeArchiveId,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.purgeId) {
          await pollPurgeStatus(data.purgeId);
        } else {
          toastWarning("Purge request failed", 3000);
          setIsDeletingData(false);
        }
      } else {
        console.error("Error initiating purge");
        toastWarning("Error initiating purge", 3000);
        setIsDeletingData(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toastWarning("Something went wrong", 3000);
      setIsDeletingData(false);
    }
  };

  const pollPurgeStatus = async (purgeId) => {
    let attempts = 0;
    let interval = 5000;

    console.log("PurgeId:", purgeId);

    const checkStatus = async () => {
      try {
        const response = await fetch(
          `${serverUrl}vehicleAndWheel/purge/status?purgeId=${purgeId}`
        );

        if (response.ok) {
          const data = await response.json();
          console.log(`Attempt ${attempts + 1}: Status - ${data.status}`);

          if (data.status === "COMPLETED") {
            setIsDeletingData(false);
            setIsBlurEffectEnable(false);
            navigate(".", { state: { isBlurEffectEnable: false } });
            setShowPurgingPopup(false);
            toastSuccess("Successfully Deleted");
            fetchTotalTestsCount();
            fetchTotalDBSize();
            fetchVehicleAndWheelTableSize();
            fetchPurgeArchiveTableData();
            return;
          } else if (data.status === "FAILED") {
            let errorDescription = data.errors.errorDescription;
            setIsDeletingData(false);
            setIsBlurEffectEnable(false);
            navigate(".", { state: { isBlurEffectEnable: false } });
            setShowPurgingPopup(false);
            toastWarning(errorDescription, 3000);
            return;
          }
        }
      } catch (error) {
        console.error("Polling error:", error);
      }

      attempts++;
      interval = Math.min(interval + 5000, 30000);
      setTimeout(checkStatus, interval);
    };

    checkStatus();
  };

  const fetchPurgeArchiveTableData = async (page) => {
    setIsLoadingListData(true);
    try {
      const response = await fetch(serverUrl + "purge/archive/get", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          count: itemsPerPage,
          pagesize: (page - 1) * itemsPerPage,
          sortBycolumn: sortBy,
          sortByFlag: reverse,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          if (
            data.entity.purgeArchiveResultsList != null &&
            data.entity.purgeArchiveResultsList.length > 0
          ) {
            setPurgeArchiveData(data.entity.purgeArchiveResultsList || []);
            // console.log("first",);
            setPurgeArchiveCount(data.entity.totalCount || 0);
            const totalPages = Math.ceil(data.entity.totalCount / itemsPerPage);
            setTotalPages(totalPages);
          } else {
            handleNoResultsFound();
          }
        } else if (data.errors != null) {
          let errorDiscription = data.errors.errorDescription;
          toastWarning(errorDiscription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);
          return;
        }
      } else {
        console.error("Error fetching Purge Archive data");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoadingListData(false);
    }
  };

  const closePurgeArchiveDeleteConfirmation = () => {
    setPurgeArchiveDeleteConfirmationOpen(false);
  };

  const openPurgeArchiveDeleteConfirmation = (e, dataToDelete) => {
    e.stopPropagation();
    setDeletePurgeArchiveData(dataToDelete);
    setPurgeArchiveDeleteConfirmationOpen(true);
  };

  const confirmDeletePurgeArchiveData = async (e) => {
    if (deletePurgeArchiveData) {
      try {
        const response = await fetch(serverUrl + "purge_archive/delete", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            purgeArchiveId: deletePurgeArchiveData.id,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.entity != null) {
            toastSuccess("Successfully Deleted");
            // Update the table data
            const updatedItems = purgeArchiveData.filter(
              (archive) => archive.id !== deletePurgeArchiveData.id
            );
            setPurgeArchiveData([...updatedItems]);
            // Reset the pagination if necessary
            setCurrentPage(1);
            fetchPurgeArchiveTableData(1);
          } else if (data.errors != null) {
            let errorDescription = data.errors.errorDescription;
            toastWarning(errorDescription, 3000);
          } else {
            toastWarning("Something Went Wrong", 3000);
          }
        } else {
          toastWarning("Server Error: Unable to delete the archive.", 3000);
        }
      } catch (error) {
        console.error("Error:", error);
        toastWarning("Network Error: Please try again later.", 3000);
      }
    }
  };

  const handlePurgeArchiveFileDownload = (e, purgeArchiveFileName) => {
    e.stopPropagation();
    if (purgeArchiveFileName && purgeArchiveFileName.trim() !== "") {
      let downloadClickableBackupFile =
        vehicleAndWheelBackupFilePath + purgeArchiveFileName;
      window.open(downloadClickableBackupFile);
      toastSuccess("Backup downloaded successfully");
    } else {
      toastWarning("Invalid file name. Unable to download backup.");
    }
  };

  const handleBackupRestoreHelpModal = () => {
    setShowBackupRestoreHelpModal(true);
  };

  const handleBackupRestoreHelpModalClose = () => {
    setShowBackupRestoreHelpModal(false);
  };

  return (
    <>
      {isLoadingListData && (
        <div className="loading-spinner">
          <ClipLoader color="#007bff" loading={isLoadingListData} size={50} />
          <p className="loading-text">Fetching Data...</p>
        </div>
      )}
      <div className="body-pd">
        <div style={{ filter: isBlurEffectEnable ? "blur(5px)" : "none" }}>
          <div className="section-Home secondary-color">
            {/* Header Section Start */}
            <Header />
            {/* Header Section End */}

            {/* Organization Drop Down Selection */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "auto",
                marginLeft: "20px",
                marginTop: "20px",
              }}
            >
              <ToastContainer />
              <label
                htmlFor="organizationName"
                style={{
                  marginRight: "10px",
                  fontWeight: "bold",
                  marginLeft: "20px",
                }}
              >
                Organizational Unit:
              </label>
              <Select
                defaultValue={null}
                styles={{
                  control: (provided) => ({ ...provided, width: "200px" }),
                  menu: (provided) => ({
                    ...provided,
                    width: "200px",
                    zIndex: "999",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    background: state.isSelected
                      ? "transparent"
                      : provided.background,
                    color: state.isSelected ? "black" : "black",
                  }),
                }}
                options={[
                  {
                    value: null,
                    label: "Select Organization",
                    isDisabled: true,
                  },
                  ...Array.from(
                    new Map(
                      ouOptions.map((ou) => [ou.ouName, ou]) // Create a Map with `ouName` as the key
                    ).values()
                  ).map((uniqueOu) => ({
                    value: uniqueOu.id,
                    label: uniqueOu.ouName,
                  })),
                ]}
                placeholder="Select Organization"
                isSearchable
                onChange={(selectedOption) => {
                  setSelectedDropdownOuId(
                    selectedOption?.value === null ? null : selectedOption.value
                  );
                }}
              />

              {/* Date Picker Selection */}
              <label
                htmlFor="DateRange"
                style={{
                  marginLeft: "24px",
                  marginRight: "5px",
                  fontWeight: "bold",
                }}
              >
                Date Range:
              </label>
              <select
                value={dateFilter || ""}
                onChange={handleDateFilterChange}
                style={{
                  padding: "6px",
                  // fontSize: "15px",
                  borderRadius: "5px",
                  marginRight: "10px",
                  border: "1px solid #ccc",
                }}
              >
                <option value="" disabled>
                  Select a date range
                </option>
                <option value="3_months">Older than three months</option>
                <option value="6_months">Older than six months</option>
                <option value="1_year">Older than one year</option>
              </select>

              <Button
                className="btns"
                onClick={handleApplyButtonClick}
                disabled={!selectedDropdownOuId || !dateFilter}
              >
                Apply
              </Button>
            </div>

            <div className="row px-4 mx-0" style={{ marginTop: "20px" }}>
              <div
                className="px-0 my-2"
                style={{ backgroundColor: "white", borderRadius: "8px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ margin: "0 10px", marginTop: "10px" }}>
                    <b>Vehicle Test</b>
                    <p>Old Vehicle Test Data</p>
                  </div>
                  <div style={{ textAlign: "center", margin: "0 10px" }}>
                    <span
                      style={{
                        color: "blue",
                        fontSize: "24px",
                        fontWeight: "bold",
                      }}
                    >
                      {totalTestsCount}
                    </span>
                    <div>
                      <b>Tests</b>
                    </div>
                  </div>
                  <div style={{ textAlign: "center", margin: "0 10px" }}>
                    <span
                      style={{
                        color: "blue",
                        fontSize: "24px",
                        fontWeight: "bold",
                      }}
                    >
                      {totalDatabaseSize}
                    </span>
                    <div>
                      <b>Total database size</b>
                    </div>
                  </div>
                  <div style={{ textAlign: "center", margin: "0 10px" }}>
                    <span
                      style={{
                        color: "blue",
                        fontSize: "24px",
                        fontWeight: "bold",
                      }}
                    >
                      {totalVehicleWithWheelDBSize}
                    </span>
                    <div>
                      <b>Total used</b>
                    </div>
                  </div>
                  <div style={{ textAlign: "center", margin: "0 10px" }}>
                    <Button
                      variant="danger"
                      className="btns"
                      disabled={totalTestsCount === 0}
                      onClick={handlePurgeClick}
                    >
                      Purge
                    </Button>
                  </div>
                </div>

                {/* Purge Modal */}
                <Modal
                  show={showPurgePopup}
                  onHide={handleClosePopup}
                  className="modal-dialog-centered"
                  animation={false}
                  backdrop="static"
                  autoFocus="true"
                >
                  <Modal.Header closeButton>
                    <div>
                      <Modal.Title>Data Purge!</Modal.Title>
                      <p
                        style={{
                          margin: 0,
                          fontSize: "14px",
                          color: "#CC0000",
                        }}
                      >
                        <strong>
                          Permanently Deleting {totalTestsCount} Vehicle Test
                          Records
                        </strong>
                      </p>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <p
                      style={{
                        border: "2px solid #CC0000",
                        backgroundColor: "#FFCCCC",
                        padding: "15px",
                        borderRadius: "5px",
                        lineHeight: "1.5",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        style={{
                          marginRight: "10px",
                          fontSize: "30px",
                          color: "#CC0000",
                        }}
                      />
                      You are about to purge the old data. This action will
                      first download the data for your records, then delete it
                      permanently from the server. <br />
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={handleClosePopup}
                      className="btns"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      onClick={handleBackupDownload}
                      className="btns"
                    >
                      Proceed
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* Backup Download Modal */}
                <Modal
                  show={showBackupDownloadPopup}
                  onHide={handleClosePopup}
                  className="modal-dialog-centered"
                  animation={false}
                  backdrop="static"
                  autoFocus="true"
                >
                  <Modal.Header
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div>
                      <Modal.Title>
                        Downloading Backup is in Progress
                      </Modal.Title>
                    </div>
                  </Modal.Header>

                  <Modal.Body>
                    {isTakingBackup && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ClipLoader
                          color="#007bff"
                          loading={isTakingBackup}
                          size={50}
                        />
                      </div>
                    )}

                    {/* Text Below Loader */}
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#6c757d",
                        textAlign: "center",
                        margin: "10px 0 0",
                      }}
                    >
                      Please wait...
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#6c757d",
                        textAlign: "center",
                        margin: "5px 0",
                      }}
                    >
                      Do not close this tab. This process might take some time.
                    </p>
                  </Modal.Body>
                </Modal>

                {/* Reconfirm Delete  Modal */}
                <Modal
                  show={showReconfirmPurgePopup}
                  onHide={handleClosePopup}
                  className="modal-dialog-centered"
                  animation={false}
                  backdrop="static"
                  autoFocus="true"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Reconfirm Delete!</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p style={{ color: "red" }}>
                      This operation cannot be undone. Proceed with caution.{" "}
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="danger"
                      className="btns"
                      onClick={handlePurgingclick}
                    >
                      Confirm Delete
                    </Button>
                    <Button
                      className="btns"
                      variant="secondary"
                      onClick={handleClosePopup}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* Purging in process Modal */}
                <Modal
                  show={showPurgingPopup}
                  onHide={handleClosePopup}
                  className="modal-dialog-centered"
                  animation={false}
                  backdrop="static"
                  autoFocus="true"
                >
                  <Modal.Header
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div>
                      <Modal.Title>
                        Vehicle Tests Delete in Progress
                      </Modal.Title>
                    </div>
                  </Modal.Header>

                  <Modal.Body>
                    {isDeletingData && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ClipLoader
                          color="#007bff"
                          loading={isDeletingData}
                          size={50}
                        />
                      </div>
                    )}

                    {/* Text Below Loader */}
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#6c757d",
                        textAlign: "center",
                        margin: "10px 0 0",
                      }}
                    >
                      Please wait...
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#6c757d",
                        textAlign: "center",
                        margin: "5px 0",
                      }}
                    >
                      Do not close this tab. This process might take some time.
                    </p>
                  </Modal.Body>
                </Modal>

                {/* Purge Archive Delete Modal */}
                <Modal
                  show={isPurgeArchiveDeleteConfirmationOpen}
                  onHide={closePurgeArchiveDeleteConfirmation}
                  className="modal-dialog-centered"
                  animation={false}
                  backdrop="static"
                  autoFocus="true"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      {" "}
                      Are you sure you want to delete this{" "}
                      <strong>
                        {deletePurgeArchiveData?.archiveFileName}
                      </strong>{" "}
                      file?
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btns"
                      variant="secondary"
                      onClick={closePurgeArchiveDeleteConfirmation}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btns"
                      variant="danger"
                      onClick={(e) => {
                        setPurgeArchiveDeleteReConfirmationOpen(true);
                        closePurgeArchiveDeleteConfirmation();
                      }}
                    >
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* Purge Archive Re-confirmation Delete Modal */}
                <Modal
                  show={isPurgeArchiveDeleteReConfirmationOpen}
                  onHide={() => setPurgeArchiveDeleteReConfirmationOpen(false)} // Close the reconfirmation modal
                  className="modal-dialog-centered"
                  animation={false}
                  backdrop="static"
                  autoFocus="true"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Reconfirm Delete</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Are you sure you want to delete this file this action is
                      irreversible.
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="danger"
                      onClick={(e) => {
                        confirmDeletePurgeArchiveData(e);
                        setPurgeArchiveDeleteReConfirmationOpen(false);
                      }}
                    >
                      Confirm Delete
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        setPurgeArchiveDeleteReConfirmationOpen(false)
                      } // Close the reconfirmation modal
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* Grey underline */}
                <div
                  style={{
                    borderBottom: "2px solid lightgrey",
                    margin: "10px 0",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                ></div>

                {/* <div
                  style={{
                    margin: "10px 0",
                    marginLeft: "15px",
                    color: "grey",
                  }}
                >
                  <b>
                    {purgeArchiveData.length > 1
                      ? "Previous Backups"
                      : "Previous Backup"}
                  </b>
                </div> */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between", // Distribute items to the left and right
                    margin: "10px 0",
                    marginLeft: "15px",
                    marginRight: "15px", // Added margin-right for symmetry
                    color: "grey",
                    alignItems: "center", // Align items vertically in the center
                  }}
                >
                  <b>
                    {purgeArchiveData.length > 1
                      ? "Previous Backups"
                      : "Previous Backup"}
                  </b>
                  <b>
                    <a
                      onClick={handleBackupRestoreHelpModal}
                      style={{
                        color: "blue",
                        marginRight: "10px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      How to restore?
                    </a>
                  </b>
                </div>

                {/* Backup Restore Help Modal */}

                <Modal
                  show={showBackupRestoreHelpModal}
                  onHide={handleBackupRestoreHelpModalClose}
                  dialogClassName="my-modal"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Restoration process of backup file
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      <strong>
                        <h5>Steps for Restoration of Backup File</h5>
                      </strong>
                    </p>

                    <p>
                      <strong>Step 1: Download and Install VS Code</strong>
                    </p>
                    <ul>
                      <li>
                        Download and install [Visual Studio Code (VS
                        Code)](https://code.visualstudio.com/) for opening large
                        backup files. Alternatively, you can use any text
                        editor, but VS Code is recommended for its performance
                        and features.
                      </li>
                    </ul>

                    <p>
                      <strong>Step 2: Open and Edit the Backup File</strong>
                    </p>
                    <ol>
                      <li>Open the backup file in VS Code.</li>
                      <li>
                        Identify the temporary table names in the backup file,
                        such as `vehicle_test_view_data` and
                        `wheel_test_view_data`, and replace them with the actual
                        table names:
                        <ul>
                          <li>
                            Replace `vehicle_test_view_data` with
                            `vehicle_test`.
                          </li>
                          <li>
                            Replace `wheel_test_view_data` with `wheel_test`.
                          </li>
                        </ul>
                      </li>
                      <li>How to Replace in VS Code:</li>
                      <ul>
                        <li>Press Ctrl + H (shortcut for Find and Replace).</li>
                        <li>
                          In the "Find" input, type the temporary table name
                          (e.g., vehicle_test_view_data).
                        </li>
                        <li>
                          In the "Replace" input, type the actual table name
                          (e.g., vehicle_test).
                        </li>
                        <li>
                          Click Replace All to make the changes across the file.
                        </li>
                        <li>Repeat the process for wheel_test_view_data.</li>
                      </ul>
                      <li>Save the file after completing the replacements.</li>
                    </ol>

                    <p>
                      <strong>Step 3: Restore the Backup File</strong>
                    </p>
                    <p>
                      You can restore the backup file using one of the two
                      methods below:
                    </p>
                    <p>
                      <b>Option 1: Restore Using MySQL Workbench</b>
                    </p>
                    <ol>
                      <li>
                        Open <b>MySQL Workbench.</b>
                      </li>
                      <li>
                        Navigate to the <b>Server</b> menu at the top and select{" "}
                        <b>Data Import</b>.
                      </li>
                      <li>
                        In the Import Options, choose{" "}
                        <b>Import from Self-Contained File</b> and click the
                        "..." (three dots) button to select your backup file..
                      </li>
                      <li>
                        Below the file selection, look for the{" "}
                        <b>Default Schema to be Imported To</b> section:
                        <ul>
                          <li>
                            Select the database name (e.g.,
                            rb_suspension_tester) from the dropdown under{" "}
                            <b>Default Target Schema</b>.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Click the <b>Start Import</b> button in the bottom right
                        corner.
                      </li>
                      <li>
                        The file will be imported and restored successfully.
                      </li>
                    </ol>

                    <p>
                      <b>Option 2: Restore Using Command Line</b>
                    </p>
                    <ol>
                      <li>
                        Open <b>Command Prompt</b> and navigate to the directory
                        where your MySQL program files are located.
                      </li>
                      <li>Run the following command to log in to MySQL:</li>
                      <code>mysql -u root -p</code>
                      <ul>
                        <li>Replace root with your MySQL username.</li>
                      </ul>
                      <ul>
                        <li>Enter your MySQL password when prompted.</li>
                      </ul>
                      <li>After logging in, select the target database:</li>
                      <code>USE rb_suspension_tester;</code>
                      <br />
                      Replace rb_suspension_tester with your database name.
                      <li>
                        Run the restore command:
                        <br />
                        <code>
                          mysql -u [username] -p [database_name] &lt;
                          /path/to/your/dumpfile.sql
                        </code>
                        <br />
                        Example:
                        <br />
                        <code>
                          mysql -u root -p rb_suspension_tester &lt;
                          /opt/wildfly/standalone/deployments/RBEAssets.war/VehicleTestRecordBackupFiles/Backup_vehicle_and_wheels_24-01-2025_01.01.31_PM.sql
                        </code>
                      </li>
                      <li>
                        Press <b>Enter</b> and input your password again if
                        prompted.
                      </li>
                      <li>
                        The restoration process will begin, and the data will be
                        imported into the specified database.
                      </li>
                    </ol>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={handleBackupRestoreHelpModalClose}
                    >
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>

                <div
                  className="card-container-test mx-0 mt-2 px-2 pb-3 "
                  style={{ maxheight: "100" }}
                >
                  <div className="employee-table">
                    <div
                      className="table-container"
                      style={{
                        maxHeight: "50vh",
                        overflow: "auto",
                      }}
                    >
                      <table>
                        <thead className="fixed-header">
                          <tr>
                            <th>Sr. No</th>
                            <th
                              className="text-left"
                              onClick={() => handleSort("ouMaster.ouName")}
                            >
                              Organization Name &nbsp;
                              {sortBy === "ouMaster.ouName" && (
                                <FontAwesomeIcon
                                  icon={reverse ? faArrowDown : faArrowUp}
                                />
                              )}
                            </th>

                            <th
                              className="text-left"
                              onClick={() => handleSort("archiveFileName")}
                            >
                              File Name &nbsp;
                              {sortBy === "archiveFileName" && (
                                <FontAwesomeIcon
                                  icon={reverse ? faArrowDown : faArrowUp}
                                />
                              )}
                            </th>

                            <th
                              className="text-left"
                              onClick={() => handleSort("archiveFileLocation")}
                            >
                              File Location &nbsp;
                              {sortBy === "archiveFileLocation" && (
                                <FontAwesomeIcon
                                  icon={reverse ? faArrowDown : faArrowUp}
                                />
                              )}
                            </th>

                            <th
                              className="text-left"
                              onClick={() => handleSort("cutoff")}
                            >
                              Cut Off &nbsp;
                              {sortBy === "cutoff" && (
                                <FontAwesomeIcon
                                  icon={reverse ? faArrowDown : faArrowUp}
                                />
                              )}
                            </th>

                            <th
                              className="text-left"
                              onClick={() => handleSort("createdDate")}
                            >
                              Created Date &nbsp;
                              {sortBy === "createdDate" && (
                                <FontAwesomeIcon
                                  icon={reverse ? faArrowDown : faArrowUp}
                                />
                              )}
                            </th>

                            <th
                              className="text-left"
                              onClick={() => handleSort("isDataPurged")}
                            >
                              Is Data Purged &nbsp;
                              {sortBy === "isDataPurged" && (
                                <FontAwesomeIcon
                                  icon={reverse ? faArrowDown : faArrowUp}
                                />
                              )}
                            </th>

                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isLoadingListData &&
                            purgeArchiveData.length > 0 &&
                            purgeArchiveData.map((archive, index) => (
                              <tr key={archive.id}>
                                <td>{startingSerialNumber + index}</td>
                                <td className="text-left">{archive.ouName}</td>
                                <td className="text-left">
                                  {archive.archiveFileName}
                                </td>
                                <td className="text-left">
                                  {archive.archiveFileLocation}
                                </td>
                                <td className="text-left">{archive.cutoff}</td>
                                <td className="text-left">
                                  {new Date(
                                    archive.createdDate
                                  ).toLocaleDateString("en-GB")}
                                </td>
                                <td className="text-left">
                                  {archive.isDataPurged ? "Yes" : "No"}
                                </td>
                                <td>
                                  <div style={{ display: "flex", gap: "15px" }}>
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faArrowCircleDown}
                                      size="lg"
                                      onClick={(e) =>
                                        handlePurgeArchiveFileDownload(
                                          e,
                                          archive.archiveFileName
                                        )
                                      }
                                    />
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      size="lg"
                                      onClick={(e) =>
                                        openPurgeArchiveDeleteConfirmation(
                                          e,
                                          archive
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {!isLoadingListData && purgeArchiveData.length === 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "15px",
                          }}
                        >
                          <img src={Notfound} alt="Not Found" />
                          <p
                            style={{
                              marginTop: "-10px",
                              fontWeight: "bold",
                            }}
                          >
                            No Purge Archive Found
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {!isLoadingListData && purgeArchiveData.length > 0 && (
                    <div className="col-md-12 mt-3 d-flex justify-content-end">
                      <div className="pagination-buttons">
                        <div className="pagination-button">
                          <div
                            className={`circle ${
                              currentPage === 1 || purgeArchiveData.length === 0
                                ? "disabled"
                                : ""
                            }`}
                            onClick={() => handlePagination("prev")}
                          >
                            <FontAwesomeIcon icon={faChevronLeft} />
                          </div>
                        </div>

                        <div className="pagination-info">
                          <p>
                            {purgeArchiveData.length > 0 ? rangeStart : 0} -{" "}
                            {purgeArchiveData.length > 0 ? rangeEnd : 0} of{" "}
                            {purgeArchiveData.length > 0
                              ? purgeArchiveCount
                              : 0}{" "}
                          </p>
                        </div>

                        <div className="pagination-button">
                          <div
                            className={`circle ${
                              currentPage === totalPages ||
                              purgeArchiveData.length === 0
                                ? "disabled"
                                : ""
                            }`}
                            onClick={() => handlePagination("next")}
                          >
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataManagement;
