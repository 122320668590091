import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { Button, Modal, Form } from "react-bootstrap";
import Header from "./Header";
import "react-toastify/dist/ReactToastify.css"; // Import styles for Toastify
import { serverUrl } from "../../App";
import { ClipLoader } from "react-spinners";
import Select from "react-select";
import { addDays, format } from "date-fns";

// export const Update = () => {
export const Update = ({
  selectedModel,
  selectedSubscriptionPlan,
  selectedOu,
  showEditModal,
  setShowEditModal,
  handleUpdateComplete,
  formType,
  showUpdateForm,
  setShowUpdateForm,
  selectedServiceStation,
}) => {
  // debugger;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [vehicleBodyTypeDropDown, setVehicleBodyTypeDropDown] = useState([]);
  const [selectedVehicleBodyType, setSelectedVehicleBodyType] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showReConfirmDialog, setShowReConfirmDialog] = useState(false);
  const handleCancelDilog = () => {
    setShowConfirmDialog(false);
  };
  const handleCancelDilogBox = () => {
    setShowReConfirmDialog(false);
  };
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [modelFormData, setModelFormData] = useState({
    makeName: "",
    modelName: "",
    frontWeight: "",
    backWeight: "",
    frontLeftSecondPeakAmplitudeThreshold: "",
    frontRightSecondPeakAmplitudeThreshold: "",
    backLeftSecondPeakAmplitudeThreshold: "",
    backRightSecondPeakAmplitudeThreshold: "",
    frontLeftMinSecondPeakAmplitudeThreshold: "",
    frontRightMinSecondPeakAmplitudeThreshold: "",
    backLeftMinSecondPeakAmplitudeThreshold: "",
    backRightMinSecondPeakAmplitudeThreshold: "",
  });
  const [subscriptionFormData, setSubscriptionFormData] = useState({
    planDescription: "",
    planDuration: "",
    maxAllowedDevices: "",
  });
  const [errors, setErrors] = useState({});
  const [selectedDropDownPlan, setSelectedDropDownPlan] = useState("");
  const [subscriptionPlanDropDown, setSubscriptionPlanDropDown] = useState([]);
  const [organizationFormData, setOrganizationFormData] = useState({
    ouName: "",
    // ouStatus: selectedOu.isDisabled ?? false,
  });
  const [expiryDate, setExpiryDate] = useState(null);
  const [showRenewConfirmDialog, setShowRenewConfirmDialog] = useState(false);
  const handleRenewCancelDilog = () => {
    setShowRenewConfirmDialog(false);
  };
  const [isRenew, setIsRenew] = useState(null);
  const [temporaryOuStatus, setTemporaryOuStatus] = useState(null);
  const [hasUserInteracted, setHasUserInteracted] = useState(null);
  const [isEdited, setIsEdited] = useState(false); // Track if the user has edited the field
  // Set the initial value only if not edited
  // const ouNameValue = isEdited
  //   ? organizationFormData.ouName
  //   : organizationFormData.ouName || selectedOu.ouName;
  const ouNameValue =
    formType === "organization"
      ? isEdited
        ? organizationFormData.ouName
        : organizationFormData.ouName || selectedOu.ouName
      : null;
  const [formData, setFormData] = useState({
    serviceStationName: "",
    serviceStationRegion: "",
    serviceStationChannel: "",
    serviceStationLocation: "",
  });

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await fetch(serverUrl + "get/all/bu/make_model", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        });
        const data = await response.json();

        if (data.entity != null) {
          if (
            data.entity.allVehicleBodyTypeList != null &&
            data.entity.allVehicleBodyTypeList.length > 0
          ) {
            setVehicleBodyTypeDropDown(data.entity.allVehicleBodyTypeList);
          } else {
            console.error("No Vehicle body type found: " + data.entity);
            toastWarning("Vehicle body type not found.", 3000);
          }
        }
        if (data.entity != null) {
          if (
            data.entity.allSubscriptionPlanList != null &&
            data.entity.allSubscriptionPlanList.length > 0
          ) {
            setSubscriptionPlanDropDown(data.entity.allSubscriptionPlanList);
          } else {
            console.error("No Subscription Plan found: " + data.entity);
            toastWarning("Subscription Plan not found.", 3000);
          }
        } else if (data.errors != null) {
          let errorDescription = data.errors.errorDescription;
          toastWarning(errorDescription, 3000);
        } else {
          toastWarning("Something Went Wrong", 3000);
        }
      } catch (err) {
        console.error("Error in fetchOptions:", err);
      }
    };

    fetchOptions();
  }, []);

  // Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  //Validation for Model Form Fields
  const handleModelFormData = (event) => {
    const { name, value } = event.target;
    let errorMessage = "";

    if (value === "") {
      setModelFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      return;
    }

    if (name === "makeName") {
      const isNameValid = /^[A-Za-z]+$/.test(value);
      if (isNameValid) {
        const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
        setModelFormData((prevData) => ({
          ...prevData,
          [name]: capitalizedValue,
        }));
      }
    } else if (name === "modelName") {
      const isModelValid = /^[A-Za-z][A-Za-z0-9\s\-().]*$/.test(value);
      if (isModelValid) {
        const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
        setModelFormData((prevData) => ({
          ...prevData,
          [name]: capitalizedValue,
        }));
      }
    } else if (name === "frontWeight" || name === "backWeight") {
      const weightRegex = /^\d{1,7}(\.\d{0,2})?$/; // Max 7 digits before and 2 after decimal
      if (weightRegex.test(value)) {
        setModelFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (
      [
        "frontLeftSecondPeakAmplitudeThreshold",
        "frontRightSecondPeakAmplitudeThreshold",
        "backLeftSecondPeakAmplitudeThreshold",
        "backRightSecondPeakAmplitudeThreshold",
        "frontLeftMinSecondPeakAmplitudeThreshold",
        "frontRightMinSecondPeakAmplitudeThreshold",
        "backLeftMinSecondPeakAmplitudeThreshold",
        "backRightMinSecondPeakAmplitudeThreshold",
      ].includes(name)
    ) {
      // Regex to match numbers with up to 11 total characters, including decimal point and optional leading minus sign
      const amplitudeRegex = /^-?\d+(\.\d{0,2})?$/;
      // Check if the total length is within 11 characters and only one minus sign is allowed at the start
      const isValidLength = value.length <= 11;
      const isValidSign = /^-?\d/.test(value); // Ensure minus is only at the start

      if (amplitudeRegex.test(value) && isValidLength && isValidSign) {
        setModelFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error message if valid
        }));
      } else {
        errorMessage = "Enter a valid threshold.";
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
      }
    }
  };

  const preventNegativeSign = (event) => {
    const { value, selectionStart } = event.target;

    // Allow minus sign only if it's at the start of the input
    if (event.key === "-" && selectionStart === 0) {
      return; // Allow minus sign if at the start
    }

    // Block additional minus signs and other invalid characters
    if (event.key === "-" || event.key === "e" || event.key === "+") {
      event.preventDefault();
    }
  };

  // Check if all required fields are filled
  const isFormValid = () => {
    debugger;
    if (formType === "model") {
      return (
        (modelFormData.makeName ||
          modelFormData.modelName ||
          modelFormData.frontWeight ||
          modelFormData.backWeight ||
          modelFormData.frontLeftSecondPeakAmplitudeThreshold ||
          modelFormData.frontRightSecondPeakAmplitudeThreshold ||
          modelFormData.backLeftSecondPeakAmplitudeThreshold ||
          modelFormData.backRightSecondPeakAmplitudeThreshold ||
          modelFormData.frontLeftMinSecondPeakAmplitudeThreshold ||
          modelFormData.frontRightMinSecondPeakAmplitudeThreshold ||
          modelFormData.backLeftMinSecondPeakAmplitudeThreshold ||
          modelFormData.backRightMinSecondPeakAmplitudeThreshold ||
          selectedVehicleBodyType) &&
        !Object.values(errorMessages).some((msg) => msg !== "")
      );
    }
    if (formType === "serviceStation") {
      return (
        (formData.serviceStationName ||
          formData.serviceStationRegion ||
          formData.serviceStationCode ||
          formData.serviceStationChannel ||
          formData.serviceStationLocation ||
          formData.deviceCount) &&
        !Object.values(errorMessages).some((msg) => msg !== "")
      );
    } else if (formType === "subscriptionPlan") {
      return (
        (subscriptionFormData.planDescription ||
          subscriptionFormData.planDuration ||
          subscriptionFormData.maxAllowedDevices) &&
        !Object.values(errors).some((msg) => msg !== "")
      );
    } else if (formType === "organization") {
      // return (
      //   (organizationFormData.ouName || selectedDropDownPlan || temporaryOuStatus) &&
      //   !Object.values(errors).some((msg) => msg !== "")
      // );
      return (
        (organizationFormData.ouName ||
          selectedDropDownPlan ||
          hasUserInteracted) &&
        !Object.values(errors).some((msg) => msg !== "")
      );
    }
  };

  const handleAddClick = (e) => {
    // debugger;
    e.preventDefault();
    setShowConfirmDialog(true);
  };

  const handleRenewClick = (e, isRenew) => {
    e.preventDefault();
    if (isRenew) {
      setShowRenewConfirmDialog(true);
      setIsRenew(true);
    }
  };

  const handleConfirm = () => {
    setShowConfirmDialog(false);
    setShowReConfirmDialog(true);
  };

  const handleReConfirm = () => {
    switch (formType) {
      case "subscriptionPlan":
        updateSubscriptionPlan();
        break;
      case "model":
        sendDataUpdateModel();
        break;
      case "organization":
        sendDataUpdateOrganization();
        break;
      case "serviceStation":
        updateServiceStation();
        break;
      default:
        // Handle unexpected form types, if necessary
        console.warn(`Unexpected formType: ${formType}`);
        break;
    }

    setShowReConfirmDialog(false);
  };

  const sendDataUpdateModel = async () => {
    // debugger;
    setIsLoading(true); // Set loading to true when sending data
    navigate(".", { state: { isLoading: true } });

    try {
      // console.log("Id:", selectedModel.id);

      // Dynamically construct the request body
      let body = {
        makeModelMasterId: selectedModel.id, // Assuming this is required
      };

      // Conditionally add properties if they have values
      if (selectedVehicleBodyType?.id) {
        body.vehicleBodyTypeId = selectedVehicleBodyType.id;
      }
      if (modelFormData.makeName) {
        body.makeName = modelFormData.makeName;
      }
      if (modelFormData.modelName) {
        body.modelName = modelFormData.modelName;
      }
      if (modelFormData.frontWeight) {
        body.frontStandardWeight = modelFormData.frontWeight;
      }
      if (modelFormData.backWeight) {
        body.backStandardWeight = modelFormData.backWeight;
      }
      if (modelFormData.frontLeftSecondPeakAmplitudeThreshold) {
        body.frontLeftSecondPeakAmplitudeThreshold =
          modelFormData.frontLeftSecondPeakAmplitudeThreshold;
      }
      if (modelFormData.frontRightSecondPeakAmplitudeThreshold) {
        body.frontRightSecondPeakAmplitudeThreshold =
          modelFormData.frontRightSecondPeakAmplitudeThreshold;
      }
      if (modelFormData.backLeftSecondPeakAmplitudeThreshold) {
        body.backLeftSecondPeakAmplitudeThreshold =
          modelFormData.backLeftSecondPeakAmplitudeThreshold;
      }
      if (modelFormData.backRightSecondPeakAmplitudeThreshold) {
        body.backRightSecondPeakAmplitudeThreshold =
          modelFormData.backRightSecondPeakAmplitudeThreshold;
      }
      if (modelFormData.frontLeftMinSecondPeakAmplitudeThreshold) {
        body.frontLeftMinSecondPeakAmplitudeThreshold =
          modelFormData.frontLeftMinSecondPeakAmplitudeThreshold;
      }
      if (modelFormData.frontRightMinSecondPeakAmplitudeThreshold) {
        body.frontRightMinSecondPeakAmplitudeThreshold =
          modelFormData.frontRightMinSecondPeakAmplitudeThreshold;
      }
      if (modelFormData.backLeftMinSecondPeakAmplitudeThreshold) {
        body.backLeftMinSecondPeakAmplitudeThreshold =
          modelFormData.backLeftMinSecondPeakAmplitudeThreshold;
      }
      if (modelFormData.backRightMinSecondPeakAmplitudeThreshold) {
        body.backRightMinSecondPeakAmplitudeThreshold =
          modelFormData.backRightMinSecondPeakAmplitudeThreshold;
      }

      const response = await fetch(serverUrl + "makemodel/save", {
        // API call to send data...
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body), // Send the dynamically created body
      });

      if (response.ok) {
        const data = await response.json();
        setIsLoading(false);
        navigate(".", { state: { isLoading: false } });

        if (data.entity != null) {
          showSuccessModelBox();
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        // Failed to send data
        console.error("Failed to send data");
      }
    } catch (error) {
      // Error occurred
      console.error("Error sending data:", error);
    } finally {
      // Set loading to false after API call is complete
      setIsLoading(false);
      navigate(".", { state: { isLoading: false } });
    }
  };

  const sendDataUpdateOrganization = async () => {
    // debugger;
    setIsLoading(true); // Set loading to true when sending data
    navigate(".", { state: { isLoading: true } });

    try {
      let body = {
        ouMasterId: selectedOu.id, // Initialize the body with the ouMasterId
      };

      // Check if renewing the subscription
      if (isRenew) {
        body.renewSubscriptionPlan = true;
        body.subscriptionPlanMasterId = selectedOu.subsMasterObj.id;
      } else {
        // If not renewing, add additional properties
        if (selectedDropDownPlan?.id) {
          body.subscriptionPlanMasterId = selectedDropDownPlan.id;
        }
        if (organizationFormData.ouName) {
          body.ouName = organizationFormData.ouName;
          // console.log("ouName", organizationFormData.ouName);
        }

        if (organizationFormData.ouStatus !== undefined) {
          body.isOUDisable = organizationFormData.ouStatus;
        }
      }
      if (isRenew) {
      setShowRenewConfirmDialog(false);
      }
      const response = await fetch(serverUrl + "ou/save", {
        // API call to send data...
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body), // Send the dynamically created body
      });

      if (response.ok) {
        const data = await response.json();
        setIsLoading(false);
        navigate(".", { state: { isLoading: false } });

        if (data.entity != null) {
          showSuccessModelBox();
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        // Failed to send data
        console.error("Failed to send data");
      }
    } catch (error) {
      // Error occurred
      console.error("Error sending data:", error);
    } finally {
      // Set loading to false after API call is complete
      setIsLoading(false);
      navigate(".", { state: { isLoading: false } });
    }
  };

  // Function to handle modal visibility
  const showSuccessModelBox = () => {
    setIsSuccessModalVisible(true);
  };

  const handleCloseSuccessModal = () => {
    setShowUpdateForm(false);
    setIsSuccessModalVisible(false);
    handleUpdateComplete();
  };

  // Validation Function for Plan Description, Duration, and Max Allowed Devices
  const validateInput = (field, value) => {
    let errorMessage = "";

    if (value.length === 0) {
      return ""; // No validation for empty fields
    }

    if (field === "planDescription") {
      // Plan Description Validation
      if (value.length > 50) {
        errorMessage = `${field} must be less than 50 characters.`;
      } else if (/[^a-zA-Z0-9\s\-_.,{}\[\]()]/.test(value)) {
        // Allow letters, numbers, spaces, hyphen, underscore, comma, dot, {}, [], ()
        errorMessage = "Invalid format.";
      } else if (value.charAt(0) !== value.charAt(0).toUpperCase()) {
        errorMessage = `${field} must start with a capital letter.`; // Uncommented this line to enforce capital letter
      }
    } else if (field === "planDuration" || field === "maxAllowedDevices") {
      // Duration and Max Allowed Devices Validation
      if (value.length > 0) {
        // Only validate if the field is not empty
        if (!/^\d+$/.test(value)) {
          errorMessage = `${field} must contain only numbers.`;
        } else if (parseInt(value, 10) < 1) {
          errorMessage = "Must be ≥ 1."; // Ensure greater than or equal to 1
        }
      }
    }

    return errorMessage;
  };

  const handleSubscriptionPlanInputChange = (field, value) => {
    // Automatically capitalize the first letter for Plan Description
    let formattedValue = value;

    if (field === "planDescription") {
      formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
    }

    // Validate the input
    const errorMessage = validateInput(field, formattedValue);

    // Set error message if any
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorMessage,
    }));

    // Update subscriptionFormData
    setSubscriptionFormData((prevState) => ({
      ...prevState,
      [field]: formattedValue,
    }));
  };

  const handleKeyDown = (field, event) => {
    if (event.key === "Backspace") {
      // Enable update state when backspace is pressed
      setOrganizationFormData((prevState) => {
        const currentValue = prevState[field];

        if (currentValue) {
          const updatedValue = currentValue.slice(0, -1); // Remove the last character

          // Error message logic
          const errorMessage =
            updatedValue.length === 0
              ? `${
                  field.charAt(0).toUpperCase() + field.slice(1)
                } is a required field.`
              : ""; // No error if not empty

          // Set error state
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: errorMessage,
          }));

          // Enable or disable update based on error state

          return { ...prevState, [field]: updatedValue }; // Update organization form data
        }

        return prevState; // No change if current value is empty
      });
    } else {
      // Handle other keys if needed
      // Example: You could implement specific logic for other keys here
      // console.log(`Key pressed: ${event.key}`); // Debugging line to see which key is pressed
    }
  };

  const updateSubscriptionPlan = async () => {
    // debugger;
    setIsLoading(true); // Set loading to true when sending data
    navigate(".", { state: { isLoading: true } });

    try {
      // Dynamically construct the request body
      let body = {
        subscriptionPlanMasterId: selectedSubscriptionPlan.id, // Assuming this is required
      };

      if (subscriptionFormData.planDescription) {
        body.planDescription = subscriptionFormData.planDescription;
      }

      if (subscriptionFormData.planDuration) {
        body.duration = subscriptionFormData.planDuration;
      }

      if (subscriptionFormData.maxAllowedDevices) {
        body.maxAllowedDevices = subscriptionFormData.maxAllowedDevices;
      }

      const response = await fetch(serverUrl + "save/subscription_plan", {
        // API call to send data...
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body), // Send the dynamically created body
      });

      if (response.ok) {
        const data = await response.json();
        setIsLoading(false);
        navigate(".", { state: { isLoading: false } });

        if (data.entity != null) {
          showSuccessModelBox();
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        // Failed to send data
        console.error("Failed to send data");
      }
    } catch (error) {
      // Error occurred
      console.error("Error sending data:", error);
    } finally {
      // Set loading to false after API call is complete
      setIsLoading(false);
      navigate(".", { state: { isLoading: false } });
    }
  };

  const handleOrganizationInputChange = (field, value) => {
    let newErrors = { ...errors };

    // Validation for OU Name
    if (field === "ouName") {
      const ouNameRegex = /^[A-Za-z\s]*$/; // Allow letters and spaces
      if (value.length === 0) {
        newErrors.ouName = "This field is required"; // Show required message if empty
      } else if (!ouNameRegex.test(value)) {
        newErrors.ouName = "Invalid format";
      } else {
        delete newErrors.ouName; // No error
      }
    }

    // Update the state with the new value
    setErrors(newErrors);
    setOrganizationFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  //Function to calculate and set expiry date
  const handlePlanChange = (selectedOption) => {
    if (selectedOption.value === null) {
      setSelectedDropDownPlan(null);
      setExpiryDate(null); // Clear expiry date when no plan is selected
    } else {
      const selectedPlan = subscriptionPlanDropDown.find(
        (option) => option.id === selectedOption.value
      );
      setSelectedDropDownPlan(selectedPlan);
      // Calculate the expiry date by adding the duration to today's date
      const today = new Date();
      const newExpiryDate = addDays(today, selectedPlan.duration);
      setExpiryDate(format(newExpiryDate, "dd/MM/yyyy")); // Format the date as needed
    }
  };

  const calculateNewExpiryDate = (currentExpiryDate, durationInDays) => {
    const durationValue = parseInt(durationInDays); // Convert duration to an integer
    const currentDate = new Date(currentExpiryDate);
    const today = new Date();

    const isExpiryDateInFuture = currentDate >= today;

    const startDate = isExpiryDateInFuture ? currentDate : today;

    startDate.setDate(startDate.getDate() + durationValue);

    // return startDate.toLocaleDateString(); // Format to local date string
    // Format to DD/MM/YYYY
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(startDate);
  };

  const currentPlanExpiryDate =
    selectedOu?.expiryDate && selectedOu?.subsMasterObj?.duration
      ? calculateNewExpiryDate(
          selectedOu.expiryDate,
          selectedOu.subsMasterObj.duration
        )
      : "TBD";

  //Colour for Expiary date
  const isNearExpiryOrExpired = (expiryDate) => {
    if (!expiryDate) return false;

    const currentDate = new Date();
    const expiry = new Date(expiryDate);

    // Calculate the difference in days
    const timeDiff = expiry - currentDate;
    const daysRemaining = timeDiff / (1000 * 60 * 60 * 24);

    // If less than 30 days remaining or if the plan is expired
    return daysRemaining <= 30;
  };

  const handleOrganizationStatusChange = (status) => {
    setHasUserInteracted(true); // Mark that the user has interacted
    setOrganizationFormData((prevData) => ({
      ...prevData,
      ouStatus: status,
    }));
  };

  const handleFormData = (e) => {
    const { name, value } = e.target;

    if (name === "serviceStationName") {
      const isNameValid =
        value === "" || /^[A-Za-z][A-Za-z0-9.\s]{0,99}$/.test(value); // Allow empty or valid name
      if (isNameValid) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Invalid input. Only letters, numbers, spaces, and dots are allowed. Should start with a letter and be less than 100 characters.",
        }));
      }
    } else if (name === "serviceStationRegion") {
      const isRegionValid = /^[A-Za-z0-9]{0,3}$/.test(value); // No changes needed here
      if (isRegionValid) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Invalid input. Alphanumeric characters only, max 3 characters.",
        }));
      }
    } else if (name === "serviceStationCode") {
      const isCodeValid = value === "" || /^[A-Za-z0-9-]{0,20}$/.test(value); // Allow empty or valid code
      if (isCodeValid) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Invalid input. Only alphanumeric characters and dashes (-) are allowed, max 20 characters.",
        }));
      }
    } else if (name === "serviceStationChannel") {
      const isChannelValid = /^[A-Za-z\s]{0,30}$/.test(value); // No changes needed here
      if (isChannelValid) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Invalid input. Only letters and spaces are allowed, max 30 characters.",
        }));
      }
    } else if (name === "serviceStationLocation") {
      const isLocationValid = /^[A-Za-z\s]{0,50}$/.test(value); // No changes needed here
      if (isLocationValid) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Invalid input. Only letters and spaces are allowed, max 50 characters.",
        }));
      }
    } else if (name === "deviceCount") {
      const count = Number(value);
      // Allow empty input to clear the error when backspace is pressed
      if (value.trim() === "") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value, // Allow empty input
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if input is empty
        }));
      } else if (Number.isInteger(count) && count > 0 && count <= 30) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid input. Must be a positive number between 1 and 30.",
        }));
      }
    }
  };

  const updateServiceStation = async () => {
    setIsLoading(true);
    navigate(".", { state: { isLoading: true } });

    try {
      let body = {
        buMasterId: selectedServiceStation.buMasterId,
      };

      if (formData.serviceStationRegion) {
        body.BuRegion = formData.serviceStationRegion;
      }

      if (formData.serviceStationName) {
        body.buName = formData.serviceStationName;
      }

      if (formData.serviceStationLocation) {
        body.BuLocation = formData.serviceStationLocation;
      }

      if (formData.serviceStationChannel) {
        body.BuChannel = formData.serviceStationChannel;
      }

      console.log(body);
      const response = await fetch(serverUrl + "bu/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const data = await response.json();
        setIsLoading(false);
        navigate(".", { state: { isLoading: false } });


        if (data.entity != null) {
          showSuccessModelBox();
        } else if (data.errors != null) {
          let errorDescription = data.errors.errorDescription;
          toastWarning(errorDescription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);
          return;
        }
      } else {
        console.error("Failed to send data");
      }
    } catch (error) {
      console.error("Error sending data:", error);
    } finally {
      setIsLoading(false);
      navigate(".", { state: { isLoading: false } });
    }
  };

  return (
    <>
      <div className="body-pd secondary-color">
        <div className="container-fluid px-0 mx-0">
          <div className="container-fluid mx-0 px-0">
            <ToastContainer />
            <div className="col-md-12 px-0">
              <div className="row px-0 mx-0">
                <div className="col-md-12 px-0">
                  <Header />
                </div>
              </div>

              <div className="row px-1 mx-0" style={{ marginTop: "20px" }}>
                <div style={{ filter: isLoading ? "blur(5px)" : "none" }}>
                  {/* Update Make Model Modal */}
                  <Modal
                    show={showUpdateForm}
                    // onHide={() => setShowUpdateForm(false)}
                    onHide={!isLoading ? () => setShowUpdateForm(false) : null}
                    dialogClassName="my-modal"
                  >
                  {isLoading && (
  <div
    style={{
      position: "absolute",
      top: "50%", 
      left: "50%", 
      transform: "translate(-30%, -30%)", 
      padding: "20px",
      borderRadius: "10px",
      zIndex: "1050", 
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <ClipLoader size={80} color="#000000" loading={isLoading} cssOverride={{ borderWidth: "5px" }} />  
  </div>
)}

                    <Modal.Header closeButton>
                      <Modal.Title className="text-center">
                        {/* Update Make Model */}
                        {formType === "model" && <>Update Make Model</>}
                        {formType === "subscriptionPlan" && (
                          <>Update Subscription Plan</>
                        )}
                        {formType === "organization" && (
                          <>Update Organization</>
                        )}
                        {formType === "serviceStation" && (
                          <>Update Service Station</>
                        )}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {/* {selectedModel && ( */}
                      {formType === "model" && selectedModel && (
                        <Form>
                          {/* Make Name and Model Name */}
                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="makeName"
                                style={{ fontWeight: "bold" }}
                              >
                                Make Name
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                name="makeName"
                                id="makeName"
                                placeholder="Enter Make Name"
                                value={modelFormData.makeName} // Bind value to formData
                                onChange={handleModelFormData}
                                required
                              />
                              {selectedModel.makeName && (
                                <div
                                  style={{
                                    fontSize: "1rem",
                                    color: "#6c757d",
                                    marginTop: "2px",
                                  }}
                                >
                                  {selectedModel.makeName}
                                </div>
                              )}
                            </div>

                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="modelName"
                                style={{ fontWeight: "bold" }}
                              >
                                Model Name
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                name="modelName"
                                id="modelName"
                                placeholder="Enter Model Name"
                                value={modelFormData.modelName} // Bind value to formData
                                onChange={handleModelFormData}
                                required
                              />
                              {selectedModel.modelName && (
                                <div
                                  style={{
                                    fontSize: "1rem",
                                    color: "#6c757d",
                                    marginTop: "2px",
                                  }}
                                >
                                  {selectedModel.modelName}
                                </div>
                              )}
                            </div>
                          </div>

                          {/* Front Weight and Back Weight */}
                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="frontWeight"
                                style={{ fontWeight: "bold" }}
                              >
                                Front Weight Distribution (kg)
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="frontWeight"
                                id="frontWeight"
                                placeholder="Enter Front Weight Distribution"
                                min="0.01"
                                value={modelFormData.frontWeight} // Bind value to formData
                                onChange={handleModelFormData}
                                onKeyDown={(e) => preventNegativeSign(e)}
                                required
                                onInput={(e) => {
                                  if (e.target.value < 0) e.target.value = "";
                                }}
                              />

                              {/* Display the standard weight for front wheels (left or right, since they're the same) */}
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) => wheel.wheelPosition === "FRONT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.standardWeight}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>

                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="backWeight"
                                style={{ fontWeight: "bold" }}
                              >
                                Back Weight Distribution (kg)
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="backWeight"
                                id="backWeight"
                                placeholder="Enter Back Weight Distribution"
                                min="0.01"
                                value={modelFormData.backWeight} // Bind value to formData
                                onChange={handleModelFormData}
                                onKeyDown={(e) => preventNegativeSign(e)}
                                required
                                onInput={(e) => {
                                  if (e.target.value < 0) e.target.value = "";
                                }}
                              />
                              {/* Display the standard weight for front wheels (left or right, since they're the same) */}
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) => wheel.wheelPosition === "BACK"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.standardWeight}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>
                          </div>

                          {/* Second Peak Amplitude Thresholds */}

                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="frontLeftMinSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Front Left Min Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="frontLeftMinSecondPeakAmplitudeThreshold"
                                id="frontLeftMinSecondPeakAmplitudeThreshold"
                                placeholder="Enter Front Left Min Threshold"
                                value={
                                  modelFormData.frontLeftMinSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "FRONT" &&
                                      wheel.wheelSide === "LEFT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.minSecondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>

                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="frontRightMinSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Front Right Min Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="frontRightMinSecondPeakAmplitudeThreshold"
                                id="frontRightMinSecondPeakAmplitudeThreshold"
                                placeholder="Enter Front Right Min Threshold"
                                value={
                                  modelFormData.frontRightMinSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "FRONT" &&
                                      wheel.wheelSide === "RIGHT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.minSecondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>
                          </div>

                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="backLeftMinSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Back Left Min Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="backLeftMinSecondPeakAmplitudeThreshold"
                                id="backLeftMinSecondPeakAmplitudeThreshold"
                                placeholder="Enter Back Left Min Threshold"
                                value={
                                  modelFormData.backLeftMinSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "BACK" &&
                                      wheel.wheelSide === "LEFT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.minSecondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>

                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="backRightMinSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Back Right Min Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="backRightMinSecondPeakAmplitudeThreshold"
                                id="backRightMinSecondPeakAmplitudeThreshold"
                                placeholder="Enter Back Right Min Threshold"
                                value={
                                  modelFormData.backRightMinSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "BACK" &&
                                      wheel.wheelSide === "RIGHT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.minSecondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>
                          </div>

                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="frontLeftSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Front Left Max Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="frontLeftSecondPeakAmplitudeThreshold"
                                id="frontLeftSecondPeakAmplitudeThreshold"
                                placeholder="Enter Front Left Max Threshold"
                                value={
                                  modelFormData.frontLeftSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "FRONT" &&
                                      wheel.wheelSide === "LEFT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.secondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>

                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="frontRightSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Front Right Max Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="frontRightSecondPeakAmplitudeThreshold"
                                id="frontRightSecondPeakAmplitudeThreshold"
                                placeholder="Enter Front Right Max Threshold"
                                value={
                                  modelFormData.frontRightSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "FRONT" &&
                                      wheel.wheelSide === "RIGHT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.secondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>
                          </div>

                          {/* Second Peak Amplitude Thresholds */}
                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="backLeftSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Back Left Max Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="backLeftSecondPeakAmplitudeThreshold"
                                id="backLeftSecondPeakAmplitudeThreshold"
                                placeholder="Enter Back Left Max Threshold"
                                value={
                                  modelFormData.backLeftSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "BACK" &&
                                      wheel.wheelSide === "LEFT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.secondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>

                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="backRightSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Back Right Max Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="backRightSecondPeakAmplitudeThreshold"
                                id="backRightSecondPeakAmplitudeThreshold"
                                placeholder="Enter Back Right Max Threshold"
                                value={
                                  modelFormData.backRightSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "BACK" &&
                                      wheel.wheelSide === "RIGHT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.secondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>
                          </div>
                          {/*Vehicle Body Type */}
                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="vehicleBodyType"
                                style={{ fontWeight: "bold" }}
                              >
                                Select Vehicle Body Type
                              </label>

                              <Select
                                value={
                                  selectedVehicleBodyType
                                    ? {
                                        value: selectedVehicleBodyType.id,
                                        label: selectedVehicleBodyType.bodyType,
                                      }
                                    : {
                                        value: null,
                                        label: "Select Body Type",
                                      }
                                }
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: "100%",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    width: "100%",
                                    zIndex: "999",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                  }),
                                }}
                                options={[
                                  {
                                    value: null,
                                    label: "Select Body Type",
                                  },
                                  ...vehicleBodyTypeDropDown
                                    .map((option) => ({
                                      value: option.id,
                                      label: option.bodyType,
                                    }))
                                    .sort((a, b) =>
                                      a.label.localeCompare(b.label)
                                    ), // Sort alphabetically by label
                                ]}
                                placeholder="Select"
                                isSearchable
                                onChange={(selectedOption) => {
                                  if (selectedOption.value === null) {
                                    setSelectedVehicleBodyType(null);
                                  } else {
                                    const selectedBodyType =
                                      vehicleBodyTypeDropDown.find(
                                        (option) =>
                                          option.id === selectedOption.value
                                      );
                                    setSelectedVehicleBodyType(
                                      selectedBodyType
                                    );
                                  }
                                }}
                              />
                              {selectedModel.vehicleBodyType.bodyType && (
                                <div
                                  style={{
                                    fontSize: "1rem",
                                    color: "#6c757d",
                                    marginTop: "2px",
                                  }}
                                >
                                  {selectedModel.vehicleBodyType.bodyType}
                                </div>
                              )}
                            </div>
                          </div>
                        </Form>
                      )}
                      {formType === "subscriptionPlan" &&
                        selectedSubscriptionPlan && (
                          <Form>
                            <div
                              className="form-row"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "25px",
                              }}
                            >
                              {/* Plan Description Field */}
                              <div
                                className="form-group"
                                style={{ flex: "0 0 48%" }}
                              >
                                <label
                                  htmlFor="planDescription"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Plan Description
                                </label>
                                <input
                                  type="text"
                                  className="form-control" // Consistent border color, no is-invalid class
                                  name="planDescription"
                                  id="planDescription"
                                  value={subscriptionFormData.planDescription}
                                  onChange={(e) =>
                                    handleSubscriptionPlanInputChange(
                                      "planDescription",
                                      e.target.value
                                    )
                                  }
                                  onKeyDown={(e) =>
                                    handleKeyDown("planDescription", e)
                                  }
                                  placeholder="Enter Plan Description"
                                />
                                {selectedSubscriptionPlan.planDescription && (
                                  <div
                                    style={{
                                      fontSize: "1rem",
                                      color: "#6c757d",
                                      marginTop: "2px",
                                    }}
                                  >
                                    {selectedSubscriptionPlan.planDescription}
                                  </div>
                                )}
                                {/* Short and simple error message displayed here */}
                                {errors.planDescription && (
                                  <div className="text-danger">
                                    {errors.planDescription}
                                  </div>
                                )}
                              </div>

                              {/* Duration Field */}
                              <div
                                className="form-group"
                                style={{ flex: "0 0 48%" }}
                              >
                                <label
                                  htmlFor="planDuration"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Duration (in Days)
                                </label>
                                <input
                                  type="number"
                                  className="form-control" // Consistent border color, no is-invalid class
                                  name="planDuration"
                                  id="planDuration"
                                  value={subscriptionFormData.planDuration}
                                  onChange={(e) =>
                                    handleSubscriptionPlanInputChange(
                                      "planDuration",
                                      e.target.value
                                    )
                                  }
                                  onKeyDown={(e) =>
                                    handleKeyDown("planDuration", e)
                                  }
                                  placeholder="Enter Duration"
                                />
                                {selectedSubscriptionPlan.duration && (
                                  <div
                                    style={{
                                      fontSize: "1rem",
                                      color: "#6c757d",
                                      marginTop: "2px",
                                    }}
                                  >
                                    {selectedSubscriptionPlan.duration}
                                  </div>
                                )}
                                {/* Short and simple error message displayed here */}
                                {errors.planDuration && (
                                  <div className="text-danger">
                                    {errors.planDuration}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div
                              className="form-row"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "25px",
                              }}
                            >
                              {/* Max Allowed Devices Field */}
                              <div
                                className="form-group"
                                style={{ flex: "0 0 48%" }}
                              >
                                <label
                                  htmlFor="maxAllowedDevices"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Max Allowed Devices
                                </label>
                                <input
                                  type="number"
                                  className="form-control" // Consistent border color, no is-invalid class
                                  name="maxAllowedDevices"
                                  id="maxAllowedDevices"
                                  value={subscriptionFormData.maxAllowedDevices}
                                  onChange={(e) =>
                                    handleSubscriptionPlanInputChange(
                                      "maxAllowedDevices",
                                      e.target.value
                                    )
                                  }
                                  onKeyDown={(e) =>
                                    handleKeyDown("maxAllowedDevices", e)
                                  }
                                  placeholder="Enter Max Allowed Devices"
                                />
                                {selectedSubscriptionPlan.maxAllowedDevices && (
                                  <div
                                    style={{
                                      fontSize: "1rem",
                                      color: "#6c757d",
                                      marginTop: "2px",
                                    }}
                                  >
                                    {selectedSubscriptionPlan.maxAllowedDevices}
                                  </div>
                                )}
                                {/* Short and simple error message displayed here */}
                                {errors.maxAllowedDevices && (
                                  <div className="text-danger">
                                    {errors.maxAllowedDevices}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Form>
                        )}
                      {formType === "organization" && selectedOu && (
                        <Form>
                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            {/* Organization Name Field */}
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="ouName"
                                style={{ fontWeight: "bold" }}
                              >
                                Organization Name
                              </label>
                              {/* <input
                                type="text"
                                className="form-control"
                                name="ouName"
                                id="ouName"
                                value={organizationFormData.ouName || selectedOu.ouName} // Show current org name if available
                                onChange={(e) => handleOrganizationInputChange("ouName", e.target.value)}
                                placeholder="Enter OU Name"
                              /> */}
                              <input
                                type="text"
                                className="form-control"
                                name="ouName"
                                id="ouName"
                                // value={
                                //   organizationFormData.ouName ||
                                //   selectedOu.ouName
                                // }
                                value={ouNameValue}
                                // onChange={(e) =>
                                //   handleOrganizationInputChange(
                                //     "ouName",
                                //     e.target.value
                                //   )
                                // }
                                onChange={(e) => {
                                  handleOrganizationInputChange(
                                    "ouName",
                                    e.target.value
                                  );
                                  setIsEdited(true); // Mark as edited
                                }}
                                onKeyDown={(e) => handleKeyDown("ouName", e)} // Attach the keydown handler
                                placeholder="Enter OU Name"
                              />
                              {errors.ouName && (
                                <div className="text-danger">
                                  {errors.ouName}
                                </div>
                              )}
                            </div>

                            {/*  OU Status Field */}
                            {/* <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label style={{ fontWeight: "bold" }}>
                                Organization Status
                              </label>
                              <div>
                                <label style={{ marginRight: "20px" }}>
                                  <input
                                    type="radio"
                                    name="ouStatus"
                                    value="true"
                                    // Use selectedOu.isDisabled if the user has not interacted, otherwise use organizationFormData.ouStatus
                                    checked={
                                      (!hasUserInteracted &&
                                        selectedOu.isDisabled) ||
                                      (hasUserInteracted &&
                                        organizationFormData.ouStatus === true)
                                    }
                                    onChange={() =>
                                      handleOrganizationStatusChange(true)
                                    }
                                  />
                                  Disabled
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    name="ouStatus"
                                    value="false"
                                    checked={
                                      (!hasUserInteracted &&
                                        !selectedOu.isDisabled) ||
                                      (hasUserInteracted &&
                                        organizationFormData.ouStatus === false)
                                    }
                                    onChange={() =>
                                      handleOrganizationStatusChange(false)
                                    }
                                  />
                                  Enabled
                                </label>
                              </div>
                            </div> */}
                          </div>

                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            {/* Subscription Plan Dropdown */}
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="subscriptionPlan"
                                style={{ fontWeight: "bold" }}
                              >
                                Select Subscription Plan
                              </label>
                              <Select
                                value={
                                  selectedDropDownPlan
                                    ? {
                                        value: selectedDropDownPlan.id,
                                        label: `${selectedDropDownPlan.planDescription} (${selectedDropDownPlan.duration} days)`,
                                      }
                                    : {
                                        value:
                                          selectedOu.subsMasterObj.id || null, // Show current plan if available
                                        label: `${selectedOu.subsMasterObj.planDescription} (${selectedOu.subsMasterObj.duration} days) (Active)`,
                                      }
                                }
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: "100%",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    width: "100%",
                                    zIndex: "999",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                  }),
                                }}
                                options={[
                                  selectedOu.subsMasterObj
                                    ? {
                                        value: null,
                                        label: `${selectedOu.subsMasterObj.planDescription} (${selectedOu.subsMasterObj.duration} days) (Active)`,
                                      }
                                    : {
                                        value: null,
                                        label: "Select Subscription Plan",
                                      },
                                  ...subscriptionPlanDropDown
                                    .filter(
                                      (option) =>
                                        option.planDescription !==
                                        selectedOu.subsMasterObj.planDescription
                                    ) // Filter out active plan
                                    .map((option) => ({
                                      value: option.id,
                                      label: `${option.planDescription} (${option.duration} days)`,
                                    }))
                                    .sort((a, b) =>
                                      a.label.localeCompare(b.label)
                                    ), // Sort alphabetically by label
                                ]}
                                placeholder="Select"
                                isSearchable
                                onChange={handlePlanChange}
                              />
                            </div>
                          </div>
                          {/* Display current plan and expiry date */}
                          {selectedOu.expiryDate && !selectedDropDownPlan && (
                            <div
                              style={{
                                border: "1px solid #dcdcdc",
                                borderRadius: "8px",
                                padding: "15px",
                                backgroundColor: "#f9f9f9",
                                marginTop: "20px",
                                maxWidth: "700px", // Limit width to make it more compact
                              }}
                            >
                              {/* Section Title */}
                              <h5
                                style={{
                                  fontWeight: "bold",
                                  color: "#333",
                                  marginBottom: "10px",
                                }}
                              >
                                Renew Current Plan
                              </h5>

                              {/* Expiry Date and Renew Button */}
                              <div
                                style={{
                                  fontSize: "1rem",
                                  color: "#6c757d",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                {/* Expiry Date */}
                                <span
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "500",
                                  }}
                                >
                                  Current Plan Expiry Date:{" "}
                                  <span
                                    style={{
                                      fontSize: "1.1rem",
                                      fontWeight: "500",
                                      color: isNearExpiryOrExpired(
                                        selectedOu.expiryDate
                                      )
                                        ? "red"
                                        : "", // Apply red color if near expiry
                                    }}
                                  >
                                    {/* {new Date(
                                      selectedOu.expiryDate
                                    ).toLocaleDateString()} */}
                                    {new Date(
                                      selectedOu.expiryDate
                                    ).toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })}
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      fontSize: "1.1rem",
                                      fontWeight: "500",
                                    }}
                                  >
                                    After renewal, plan expires on:{" "}
                                    {currentPlanExpiryDate}
                                  </span>
                                </span>

                                {/* Renew Button */}
                                <Button
                                  type="button"
                                  className="btn btn-success"
                                  style={{
                                    padding: "10px 20px",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    borderRadius: "5px",
                                    marginLeft: "20px",
                                  }}
                                  onClick={(e) => handleRenewClick(e, true)}
                                  // disabled={selectedDropDownPlan} // Disable if a subscription plan is selected
                                >
                                  Renew
                                </Button>
                              </div>
                            </div>
                          )}

                          {/* Display selected plan details if a plan is chosen */}
                          {selectedOu.expiryDate && selectedDropDownPlan && (
                            <div
                              style={{
                                border: "1px solid #dcdcdc",
                                borderRadius: "8px",
                                padding: "15px",
                                backgroundColor: "#f9f9f9",
                                marginTop: "20px",
                                maxWidth: "700px",
                              }}
                            >
                              <h5
                                style={{
                                  fontWeight: "bold",
                                  color: "#333",
                                  marginBottom: "10px",
                                }}
                              >
                                Selected Subscription Plan Details
                              </h5>

                              <div
                                style={{
                                  fontSize: "1rem",
                                  color: "#6c757d",
                                  display: "flex",
                                  flexDirection: "column", // Stack details vertically
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "500",
                                  }}
                                >
                                  Current Plan Expiry Date :{" "}
                                  <span
                                    style={{
                                      fontSize: "1.1rem",
                                      fontWeight: "500",
                                      color: isNearExpiryOrExpired(
                                        selectedOu.expiryDate
                                      )
                                        ? "red"
                                        : "", // Apply red color if near expiry
                                    }}
                                  >
                                    {new Date(
                                      selectedOu.expiryDate
                                    ).toLocaleDateString()}
                                  </span>
                                </span>
                                <span
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "500",
                                  }}
                                >
                                  Selected Plan Expires On: {expiryDate}
                                </span>
                              </div>
                            </div>
                          )}
                        </Form>
                      )}

                      {formType === "serviceStation" &&
                        selectedServiceStation && (
                          <Form>
                            <div
                              className="form-row"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "25px",
                              }}
                            >
                              <div
                                className="form-group"
                                style={{ flex: "0 0 48%" }}
                              >
                                <label
                                  htmlFor="serviceStationName"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Service Station Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="serviceStationName"
                                  id="serviceStationName"
                                  placeholder="Enter Service Station Name"
                                  value={formData.serviceStationName} // Bind value to formData
                                  onChange={handleFormData}
                                />
                                {selectedServiceStation.buName && (
                                  <div
                                    style={{
                                      fontSize: "1rem",
                                      color: "#6c757d",
                                      marginTop: "2px",
                                    }}
                                  >
                                    {selectedServiceStation.buName}
                                  </div>
                                )}
                              </div>
                              <div
                                className="form-group"
                                style={{ flex: "0 0 48%" }}
                              >
                                <label
                                  htmlFor="serviceStationRegion"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Service Station Region
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="serviceStationRegion"
                                  id="serviceStationRegion"
                                  value={formData.serviceStationRegion}
                                  onChange={handleFormData}
                                  placeholder="Enter Service Station Region"
                                />
                                {selectedServiceStation.buRegion && (
                                  <div
                                    style={{
                                      fontSize: "1rem",
                                      color: "#6c757d",
                                      marginTop: "2px",
                                    }}
                                  >
                                    {selectedServiceStation.buRegion}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div
                              className="form-row"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className="form-group"
                                style={{ flex: "0 0 48%" }}
                              >
                                <label
                                  htmlFor="serviceStationLocation"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Service Station Channel
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="serviceStationChannel"
                                  id="serviceStationChannel"
                                  value={formData.serviceStationChannel}
                                  onChange={handleFormData}
                                  placeholder="Enter Service Station Channel"
                                />
                                {selectedServiceStation.buChannel && (
                                  <div
                                    style={{
                                      fontSize: "1rem",
                                      color: "#6c757d",
                                      marginTop: "2px",
                                    }}
                                  >
                                    {selectedServiceStation.buChannel}
                                  </div>
                                )}
                              </div>
                              <div
                                className="form-group"
                                style={{
                                  flex: "0 0 48%",
                                  marginBottom: "25px",
                                }}
                              >
                                <label
                                  htmlFor="serviceStationLocation"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Service Station Location
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="serviceStationLocation"
                                  id="serviceStationLocation"
                                  value={formData.serviceStationLocation}
                                  onChange={handleFormData}
                                  placeholder="Enter Service Station Location"
                                />
                                {selectedServiceStation.buLocation && (
                                  <div
                                    style={{
                                      fontSize: "1rem",
                                      color: "#6c757d",
                                      marginTop: "2px",
                                    }}
                                  >
                                    {selectedServiceStation.buLocation}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div
                              className="form-row"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            ></div>
                          </Form>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="btn btn-secondary"
                        onClick={() => setShowUpdateForm(false)}
                        disabled={isLoading} 
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn btn-primary"
                        // onClick={handleSaveChanges}
                        onClick={(e) => handleAddClick(e)}
                        // disabled={!isFormValid()}
                        disabled={!isFormValid() || isLoading}
                      >
                        Update
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <Modal
                    show={showReConfirmDialog}
                    onHide={handleCancelDilogBox}
                    dialogClassName="my-modal"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Reconfirm Update</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {formType === "model" && selectedModel && (
                        <>
                          <div
                            style={{ marginBottom: "15px", fontSize: "1.2rem" }}
                          >
                            Do you really want to apply these modifications to
                            the model?
                          </div>
                          <table
                            className="table no-border"
                            style={{
                              borderCollapse: "collapse",
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            <thead>
                              <tr>
                                {/* {" "} */}
                                {/* Background color is now transparent */}
                                <th style={{ textAlign: "left" }}>Field</th>
                                <th style={{ textAlign: "left" }}>Current</th>
                                <th style={{ textAlign: "left" }}>New</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Make Name */}
                              {modelFormData.makeName && (
                                <tr>
                                  <td style={{ textAlign: "left" }}>
                                    Make Name
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {selectedModel.makeName}
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {modelFormData.makeName}
                                  </td>
                                </tr>
                              )}

                              {/* Model Name */}
                              {modelFormData.modelName && (
                                <tr>
                                  <td style={{ textAlign: "left" }}>
                                    Model Name
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {selectedModel.modelName}
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {modelFormData.modelName}
                                  </td>
                                </tr>
                              )}

                              {/* Vehicle Body Type */}
                              {selectedVehicleBodyType &&
                                selectedVehicleBodyType.bodyType && (
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      Vehicle Body Type
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {selectedModel.vehicleBodyType.bodyType}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {selectedVehicleBodyType.bodyType}
                                    </td>
                                  </tr>
                                )}

                              {/* Wheel positions and standard weights */}
                              {Array.from(
                                new Set(
                                  selectedModel.wheelMaster.map(
                                    (wheel) => wheel.wheelPosition
                                  )
                                )
                              )
                                .sort((a, b) => b.localeCompare(a)) // Sort positions in descending order
                                .map((position) => {
                                  const wheel = selectedModel.wheelMaster.find(
                                    (w) => w.wheelPosition === position
                                  );
                                  const newWeight =
                                    modelFormData[
                                      `${position.toLowerCase()}Weight`
                                    ];
                                  if (newWeight) {
                                    return (
                                      <tr key={position}>
                                        <td style={{ textAlign: "left" }}>
                                          {position.charAt(0).toUpperCase() +
                                            position
                                              .slice(1)
                                              .toLowerCase()}{" "}
                                          Wheel Standard Weight
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                          {wheel.standardWeight}
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                          {newWeight}
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return null;
                                })}

                              {/* Front Left MIN SPA Threshold */}
                              {modelFormData.frontLeftMinSecondPeakAmplitudeThreshold && (
                                <tr>
                                  <td style={{ textAlign: "left" }}>
                                    Front Left Min SPA Threshold
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      selectedModel.wheelMaster.find(
                                        (wheel) =>
                                          wheel.wheelPosition === "FRONT" &&
                                          wheel.wheelSide === "LEFT"
                                      )?.minSecondPeakAmplitudeThreshold
                                    }
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      modelFormData.frontLeftMinSecondPeakAmplitudeThreshold
                                    }
                                  </td>
                                </tr>
                              )}

                              {/* Front Right MIN SPA Threshold */}
                              {modelFormData.frontRightMinSecondPeakAmplitudeThreshold && (
                                <tr>
                                  <td style={{ textAlign: "left" }}>
                                    Front Right Min SPA Threshold
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      selectedModel.wheelMaster.find(
                                        (wheel) =>
                                          wheel.wheelPosition === "FRONT" &&
                                          wheel.wheelSide === "RIGHT"
                                      )?.minSecondPeakAmplitudeThreshold
                                    }
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      modelFormData.frontRightMinSecondPeakAmplitudeThreshold
                                    }
                                  </td>
                                </tr>
                              )}

                              {/* Back Left MIN SPA Threshold */}
                              {modelFormData.backLeftMinSecondPeakAmplitudeThreshold && (
                                <tr>
                                  <td style={{ textAlign: "left" }}>
                                    Back Left Min SPA Threshold
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      selectedModel.wheelMaster.find(
                                        (wheel) =>
                                          wheel.wheelPosition === "BACK" &&
                                          wheel.wheelSide === "LEFT"
                                      )?.minSecondPeakAmplitudeThreshold
                                    }
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      modelFormData.backLeftMinSecondPeakAmplitudeThreshold
                                    }
                                  </td>
                                </tr>
                              )}

                              {/* Back Right MIN SPA Threshold */}
                              {modelFormData.backRightMinSecondPeakAmplitudeThreshold && (
                                <tr>
                                  <td style={{ textAlign: "left" }}>
                                    Back Right Min SPA Threshold
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      selectedModel.wheelMaster.find(
                                        (wheel) =>
                                          wheel.wheelPosition === "BACK" &&
                                          wheel.wheelSide === "RIGHT"
                                      )?.minSecondPeakAmplitudeThreshold
                                    }
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      modelFormData.backRightMinSecondPeakAmplitudeThreshold
                                    }
                                  </td>
                                </tr>
                              )}

                              {/* Front Left MAX SPA Threshold */}
                              {modelFormData.frontLeftSecondPeakAmplitudeThreshold && (
                                <tr>
                                  <td style={{ textAlign: "left" }}>
                                    Front Left Max SPA Threshold
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      selectedModel.wheelMaster.find(
                                        (wheel) =>
                                          wheel.wheelPosition === "FRONT" &&
                                          wheel.wheelSide === "LEFT"
                                      )?.secondPeakAmplitudeThreshold
                                    }
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      modelFormData.frontLeftSecondPeakAmplitudeThreshold
                                    }
                                  </td>
                                </tr>
                              )}

                              {/* Front Right MAX SPA Threshold */}
                              {modelFormData.frontRightSecondPeakAmplitudeThreshold && (
                                <tr>
                                  <td style={{ textAlign: "left" }}>
                                    Front Right Max SPA Threshold
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      selectedModel.wheelMaster.find(
                                        (wheel) =>
                                          wheel.wheelPosition === "FRONT" &&
                                          wheel.wheelSide === "RIGHT"
                                      )?.secondPeakAmplitudeThreshold
                                    }
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      modelFormData.frontRightSecondPeakAmplitudeThreshold
                                    }
                                  </td>
                                </tr>
                              )}

                              {/* Back Left MAX SPA Threshold */}
                              {modelFormData.backLeftSecondPeakAmplitudeThreshold && (
                                <tr>
                                  <td style={{ textAlign: "left" }}>
                                    Back Left Max SPA Threshold
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      selectedModel.wheelMaster.find(
                                        (wheel) =>
                                          wheel.wheelPosition === "BACK" &&
                                          wheel.wheelSide === "LEFT"
                                      )?.secondPeakAmplitudeThreshold
                                    }
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      modelFormData.backLeftSecondPeakAmplitudeThreshold
                                    }
                                  </td>
                                </tr>
                              )}

                              {/* Back Right MAX SPA Threshold */}
                              {modelFormData.backRightSecondPeakAmplitudeThreshold && (
                                <tr>
                                  <td style={{ textAlign: "left" }}>
                                    Back Right Max SPA Threshold
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      selectedModel.wheelMaster.find(
                                        (wheel) =>
                                          wheel.wheelPosition === "BACK" &&
                                          wheel.wheelSide === "RIGHT"
                                      )?.secondPeakAmplitudeThreshold
                                    }
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {
                                      modelFormData.backRightSecondPeakAmplitudeThreshold
                                    }
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </>
                      )}
                      {formType === "subscriptionPlan" &&
                        selectedSubscriptionPlan && (
                          <div style={{ marginTop: "20px" }}>
                            <div
                              style={{
                                marginBottom: "15px",
                                fontSize: "1.2rem",
                              }}
                            >
                              Do you really want to apply these modifications to
                              the subscription plan?
                            </div>
                            <table
                              className="table no-border"
                              style={{
                                borderCollapse: "collapse",
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "left" }}>Field</th>
                                  <th style={{ textAlign: "left" }}>Current</th>
                                  <th style={{ textAlign: "left" }}>New</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* Plan Description */}
                                {subscriptionFormData.planDescription && (
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      Plan Description
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {selectedSubscriptionPlan.planDescription}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {subscriptionFormData.planDescription}
                                    </td>
                                  </tr>
                                )}
                                {/* Duration */}
                                {subscriptionFormData.planDuration && (
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      Duration (in Days)
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {selectedSubscriptionPlan.duration}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {subscriptionFormData.planDuration}
                                    </td>
                                  </tr>
                                )}
                                {/* Max Allowed Devices */}
                                {subscriptionFormData.maxAllowedDevices && (
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      Max Allowed Devices
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {
                                        selectedSubscriptionPlan.maxAllowedDevices
                                      }
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {subscriptionFormData.maxAllowedDevices}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}
                      {formType === "organization" && selectedOu && (
                        <div>
                          <div
                            style={{
                              marginBottom: "15px",
                              fontSize: "1.2rem",
                            }}
                          >
                            Do you really want to apply these modifications to
                            the Organization?
                          </div>
                          <table
                            style={{
                              width: "100%",
                              marginBottom: "20px",
                              borderCollapse: "collapse",
                            }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    textAlign: "left",
                                    padding: "10px",
                                    borderBottom: "1px solid #ddd",
                                  }}
                                >
                                  Field
                                </th>
                                <th
                                  style={{
                                    textAlign: "left",
                                    padding: "10px",
                                    borderBottom: "1px solid #ddd",
                                  }}
                                >
                                  Old Value
                                </th>
                                <th
                                  style={{
                                    textAlign: "left",
                                    padding: "10px",
                                    borderBottom: "1px solid #ddd",
                                  }}
                                >
                                  New Value
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Organization Name */}
                              {organizationFormData.ouName && (
                                <tr>
                                  <td
                                    style={{
                                      padding: "10px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Organization Name
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {selectedOu.ouName}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {organizationFormData.ouName}
                                  </td>
                                </tr>
                              )}

                              {/* Subscription Plan */}
                              {selectedDropDownPlan &&
                                selectedDropDownPlan.planDescription && (
                                  <tr>
                                    <td
                                      style={{
                                        padding: "10px",
                                        textAlign: "left",
                                      }}
                                    >
                                      Subscription Plan
                                    </td>
                                    <td
                                      style={{
                                        padding: "10px",
                                        textAlign: "left",
                                      }}
                                    >
                                      {selectedOu.subsMasterObj.planDescription}
                                    </td>
                                    <td
                                      style={{
                                        padding: "10px",
                                        textAlign: "left",
                                      }}
                                    >
                                      {selectedDropDownPlan.planDescription}
                                    </td>
                                  </tr>
                                )}

                              {/* OU Disable Status */}
                              {hasUserInteracted && (
                                <tr>
                                  <td
                                    style={{
                                      padding: "10px",
                                      textAlign: "left",
                                    }}
                                  >
                                    OU Disable
                                  </td>

                                  {/* Display selectedOu.isDisabled status */}
                                  <td
                                    style={{
                                      padding: "10px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {selectedOu.isDisabled !== undefined
                                      ? selectedOu.isDisabled
                                        ? "True"
                                        : "False"
                                      : "N/A"}
                                  </td>

                                  {/* Display temporaryOuStatus */}
                                  <td
                                    style={{
                                      padding: "10px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {organizationFormData.ouStatus !== undefined
                                      ? organizationFormData.ouStatus
                                        ? "True"
                                        : "False"
                                      : "N/A"}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {formType === "serviceStation" &&
                        selectedServiceStation && (
                          <div>
                            <div
                              style={{
                                marginBottom: "15px",
                                fontSize: "1.2rem",
                              }}
                            >
                              Do you really want to apply these modifications to
                              the service station?
                            </div>
                            <table
                              className="table no-border"
                              style={{
                                borderCollapse: "collapse",
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "left" }}>Field</th>
                                  <th style={{ textAlign: "left" }}>Current</th>
                                  <th style={{ textAlign: "left" }}>New</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* Service Station Region */}
                                {formData.serviceStationRegion && (
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      Service Station Region
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {selectedServiceStation.buRegion}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {formData.serviceStationRegion}
                                    </td>
                                  </tr>
                                )}

                                {/* Service Station Name */}
                                {formData.serviceStationName && (
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      Service Station Name
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {selectedServiceStation.buName}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {formData.serviceStationName}
                                    </td>
                                  </tr>
                                )}

                                {/* Service Station Channel */}
                                {formData.serviceStationChannel && (
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      Service Station Channel
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {selectedServiceStation.buChannel}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {formData.serviceStationChannel}
                                    </td>
                                  </tr>
                                )}

                                {/* Service Station Location */}
                                {formData.serviceStationLocation && (
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      Service Station Location
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {selectedServiceStation.buLocation}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {formData.serviceStationLocation}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleCancelDilogBox}
                      >
                        Cancel
                      </Button>
                      <Button variant="primary" onClick={handleReConfirm}  disabled={isLoading} >
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <Modal show={showConfirmDialog} onHide={handleCancelDilog}>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {formType === "model" && (
                        <>Are you sure you want to Update this model?</>
                      )}
                      {formType === "subscriptionPlan" && (
                        <>
                          Are you sure you want to Update this Subscription
                          Plan?
                        </>
                      )}
                      {formType === "organization" && (
                        <>Are you sure you want to Update this Organization?</>
                      )}
                      {formType === "serviceStation" && (
                        <>
                          Are you sure you want to Update this Service Station?
                        </>
                      )}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCancelDilog}>
                        Cancel
                      </Button>
                      <Button variant="primary" onClick={handleConfirm}>
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal
                    show={showRenewConfirmDialog}
                    onHide={handleRenewCancelDilog}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {formType === "organization" && (
                        <>Are you sure you want to Renew this Plan?</>
                      )}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleRenewCancelDilog}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => sendDataUpdateOrganization(true)}
                        disabled={isLoading} 
                      >
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* success Dialog Box*/}
                  <Modal
                    show={isSuccessModalVisible}
                    onHide={handleCloseSuccessModal}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {/* Model Updated successfully..! */}
                      {formType === "model" && (
                        <>Model Updated successfully..!</>
                      )}
                      {formType === "subscriptionPlan" && (
                        <>Subscription Plan Updated successfully..!</>
                      )}
                      {formType === "organization" && (
                        <>
                          {isRenew ? (
                            <>Plan Renewed Successfully!</>
                          ) : temporaryOuStatus ? (
                            <>Ou Status Change Successfully..! </> // Add the message or component you want to display
                          ) : (
                            <>Organization Updated Successfully..!</>
                          )}
                        </>
                      )}
                      {formType === "serviceStation" && (
                        <>Service Station Updated successfully..!</>
                      )}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleCloseSuccessModal}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Update;
