import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faChevronLeft,
  faChevronRight,
  faXmark,
  faArrowUp,
  faArrowDown,
  faTrash,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { serverUrl } from "../../App";
import { toast, ToastContainer } from "react-toastify";
import { Button, Modal, Form } from "react-bootstrap";
import { assetPath } from "../../App";
import { ClipLoader } from "react-spinners";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Header from "./Header";
import Notfound from "../../Assets/NoResultFound.png";

const GetAllOuPropertyList = () => {
  const location = useLocation();
  const [userMasterObject, setUserMasterObject] = useState(null);
  const navigate = useNavigate();
  const [searchTest, setsearchTest] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [ouPropertyData, setOuPropertyData] = useState([]);
  const [ouPropertyCount, setOuPropertyCount] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoadingListData, setIsLoadingListData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const startingSerialNumber = (currentPage - 1) * itemsPerPage + 1;
  const rangeStart = (currentPage - 1) * itemsPerPage + 1;
  const rangeEnd = Math.min(
    rangeStart + ouPropertyData.length - 1,
    currentPage * itemsPerPage
  );
  const [ouPropertySorting, setOuPropertySorting] = useState("createdDate");
  const [ouPropertyReverse, setOuPropertyReverse] = useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [ouOptions, setOuOptions] = useState([]);
  const [selectedDropdownOuId, setSelectedDropdownOuId] = useState();
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedOuProperty, setSelectedOuProperty] = useState(null);
  const hasToastShownRef = useRef(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showReConfirmDialog, setShowReConfirmDialog] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [selectedDropDownProperty, setSelectedDropDownProperty] =
    useState(null);

  const initialFormData = {
    propertyValue: "",
  };
  const [assignPropertyFormData, setAssignPropertyFormData] =
    useState(initialFormData);
  const resetForm = () => setAssignPropertyFormData(initialFormData);
  const [propertyMasterListDropDown, setPropertyMasterListDropDown] = useState(
    []
  );
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Get Data From Session
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");

        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);
          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            const ouMasterId =
              parsedUserData.entity.roleOuObjs[0].ouMasterObj.id;
            setUserMasterObject({
              firstName: parsedUserData.entity.firstName,
              lastName: parsedUserData.entity.lastName,
              designation: parsedUserData.entity.designation,
              ouMasterId,
            });

            if (parsedUserData?.entity?.roleOuObjs?.length > 0) {
              const uniqueOUs = parsedUserData.entity.roleOuObjs.map(
                (role) => ({
                  id: role.ouMasterObj.id,
                  ouName: role.ouMasterObj.ouName,
                })
              );
              const uniqueOUsFiltered = uniqueOUs.filter(
                (ou, index, self) =>
                  index ===
                  self.findIndex(
                    (t) => t.id === ou.id && t.ouName === ou.ouName
                  )
              );
              setOuOptions(uniqueOUsFiltered);
            }
          } else {
            toastWarning("User data not available.", 3000);
            return;
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };
    fetchData();
  }, [navigate]);

  useEffect(() => {
    const fetchListData = async () => {
      if (userMasterObject) {
        await fetchOuPropertyData(currentPage);
      }
    };
    fetchListData();
  }, [userMasterObject, currentPage, ouPropertySorting, ouPropertyReverse]);

  useEffect(() => {
    if (userMasterObject && searchInputValue === "") {
      setCurrentPage(1);
      fetchOuPropertyData(1);
    }
  }, [userMasterObject, searchInputValue]);

  useEffect(() => {
    if (isSearchButtonClicked) {
      if (!searchInputValue.trim()) {
        toastWarning("Please enter a search keyword.", 3000);
        setIsSearchButtonClicked(false);
        return;
      }
      setsearchTest(searchInputValue);
      setCurrentPage(1);
      fetchOuPropertyData(1);
      setIsSearchButtonClicked(false);
    }
  }, [isSearchButtonClicked, searchInputValue]);

  useEffect(() => {
    if (ouPropertyData.length > 0) {
      hasToastShownRef.current = false;
    }
  }, [ouPropertyData]);

  useEffect(() => {
    if (searchTest && userMasterObject) {
      setCurrentPage(1);
      fetchOuPropertyData(1);
      setIsSearchButtonClicked(false);
    }
  }, [searchTest]);

  useEffect(() => {
    if (selectedDropdownOuId !== null) {
      setCurrentPage(1);
      fetchOuPropertyData(1);
    }
    setCurrentPage(1);
    fetchOuPropertyData(1);
  }, [selectedDropdownOuId]);

  useEffect(() => {
    const fetchAllPropertyData = async () => {
      fetchOptions();
    };

    fetchAllPropertyData();
  }, [userMasterObject]);

  const fetchOptions = async () => {
    try {
      await fetch(serverUrl + "get/all/bu/make_model", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: userMasterObject.ouMasterId,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.entity != null) {
            if (
              data.entity.allPropertyList != null &&
              data.entity.allPropertyList.length > 0
            ) {
              setPropertyMasterListDropDown(data.entity.allPropertyList);
            } else {
              console.error("No Property Data found: " + data.entity);
              toastWarning("Make Property Data found.", 3000);
              return;
            }
          } else if (data.errors != null) {
            let errorDiscription = data.errors.errorDescription;

            toastWarning(errorDiscription, 3000);

            return;
          } else {
            toastWarning("Something Went Wrong", 3000);

            return;
          }
        });
    } catch (err) {}
  };

  const handleCreateButtonClick = () => {
    navigate(`/create?form=ouProperty`);
  };

  const handleUpdateClick = (item) => {
    setSelectedOuProperty(item);
    setShowUpdateForm(true);
  };

  const handleUpdateComplete = () => {
    setCurrentPage(1);
    fetchOuPropertyData(1);
  };

  const exportOuPropertyList = async () => {
    try {
      const response = await fetch(
        serverUrl + "registeredOuProperties/export",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ouMasterId: selectedDropdownOuId,
            searchText: searchTest || "",
            sortBycolumn: ouPropertySorting,
            sortByFlag: ouPropertyReverse,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          let exportFile = assetPath + data.entity;

          window.open(exportFile, "_blank");

          toastSuccess("Opening CSV in a new tab!");
        } else if (data.errors != null) {
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        console.error("Error exporting CSV");
        toastWarning("Error exporting CSV", 3000);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Success Notification
  const toastSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "green" },
    });
  };

  // Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  // Pagination Function
  const handlePagination = async (direction) => {
    let newPage;
    if (direction === "prev" && currentPage > 1) {
      newPage = currentPage - 1;
    } else if (direction === "next" && currentPage < totalPages) {
      newPage = currentPage + 1;
    } else {
      return;
    }
    setCurrentPage(newPage);
  };

  const handleNoResultsFound = () => {
    if (currentPage === 1 && !hasToastShownRef.current) {
      hasToastShownRef.current = true;

      toastWarning("No Organization Property Found", 3000);
      setOuPropertyData([]);
    }

    setOuPropertyCount(null);
    setTotalPages(0);
  };

  const handleInputChange = (e) => {
    setSearchInputValue(e.target.value);
  };

  const handleSearch = () => {
    setIsSearchButtonClicked(true);
    if (!searchInputValue.trim()) {
      return;
    }
    setsearchTest(searchInputValue);
    setIsSearchButtonClicked(false);
  };

  const fetchOuPropertyData = async (page) => {
    setIsLoadingListData(true);
    try {
      const response = await fetch(serverUrl + "/get/ouProperty", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: selectedDropdownOuId,
          searchText: searchTest || "",
          count: itemsPerPage,
          pagesize: (page - 1) * itemsPerPage,
          sortBycolumn: ouPropertySorting,
          sortByFlag: ouPropertyReverse,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          if (
            data.entity.ouPropertyResultsList != null &&
            data.entity.ouPropertyResultsList.length > 0
          ) {
            setOuPropertyData(data.entity.ouPropertyResultsList || []);
            setOuPropertyCount(data.entity.totalCount || 0);
            const totalPages = Math.ceil(data.entity.totalCount / itemsPerPage);
            setTotalPages(totalPages);
          } else {
            handleNoResultsFound();
          }
        } else if (data.errors != null) {
          let errorDiscription = data.errors.errorDescription;
          toastWarning(errorDiscription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);
          return;
        }
      } else {
        console.error("Error fetching Organization Property data");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoadingListData(false);
    }
  };

  const handleAddClick = (e) => {
    debugger;
    e.preventDefault();
    setShowUpdateForm(false);
    setShowConfirmDialog(true);
  };

  const handleConfirm = () => {
    setShowConfirmDialog(false);
    setShowReConfirmDialog(true);
  };

  const handleCancelDilog = () => {
    setShowConfirmDialog(false);
    resetForm();
  };

  const handleCancelDilogBox = () => {
    setShowReConfirmDialog(false);
    resetForm();
  };

  const handleCancleClick = (e) => {
    setShowUpdateForm(false);
    resetForm();
    setError(null);
  };

  const showSuccessModelBox = () => {
    setIsSuccessModalVisible(true);
    setShowUpdateForm(false);
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalVisible(false);
    handleUpdateComplete();
    resetForm();
  };

  const handleReConfirm = () => {
    updateOuProperty();
    setShowReConfirmDialog(false);
  };

  const updateOuProperty = async () => {
    debugger;
    setIsLoading(true);
    navigate(".", { state: { isLoading: true } });
    try {
      let body = {
        ouPropertyId: selectedOuProperty.id,
      };

      if (assignPropertyFormData.propertyValue) {
        body.propertyValue = assignPropertyFormData.propertyValue;
      }

      if (selectedDropDownProperty) {
        body.propertyMasterId = selectedDropDownProperty.id;
      }

      const response = await fetch(serverUrl + "save/ouProperty", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const data = await response.json();
        setIsLoading(false);
        navigate(".", { state: { isLoading: false } });

        if (data.entity != null) {
          showSuccessModelBox();
        } else if (data.errors != null) {
          let errorDescription = data.errors.errorDescription;
          toastWarning(errorDescription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);
          return;
        }
      } else {
        console.error("Failed to send data");
      }
    } catch (error) {
      console.error("Error sending data:", error);
    } finally {
      setIsLoading(false);
      navigate(".", { state: { isLoading: false } });
    }
  };

  const isFormValid = () => {
    return (
      assignPropertyFormData.propertyValue &&
      !Object.values(error).some((msg) => msg !== "")
    );
  };

  const handleDropDownInputChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedDropDownProperty({
        id: selectedOption.value,
        propertyName: selectedOption.label,
      });
    } else {
      setSelectedDropDownProperty(null);
    }
  };

  const handleouPropertySort = (newSortBy) => {
    setOuPropertyReverse((prevReverse) => !prevReverse);
    setOuPropertySorting(newSortBy);
    setCurrentPage(1);
  };

  const handlePropertyValueValidation = (e) => {
    const value = e.target.value;

    if (value.toLowerCase() === "true" || value.toLowerCase() === "false") {
      setError("The values 'true' and 'false' are not allowed.");
    } else {
      setError("");
    }

    setAssignPropertyFormData((prev) => ({
      ...prev,
      propertyValue: value,
    }));
  };

  const toViewProperties = () => {
    navigate(`/getAllPropertyList`);
  };

  return (
    <>
      {isLoadingListData && (
        <div className="loading-spinner">
          <ClipLoader color="#007bff" loading={isLoadingListData} size={50} />
          <p className="loading-text"></p>
        </div>
      )}

       {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%", 
            left: "50%", 
            transform: "translate(-30%, -30%)", 
            padding: "20px",
            borderRadius: "10px",
            zIndex: "1050", 
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ClipLoader size={80} color="#000000" loading={isLoading} cssOverride={{ borderWidth: "5px" }} />  
        </div>
      )}

      <div className="body-pd secondary-color">
        <div className="container-fluid px-0 mx-0 main">
          <div className="px-0 mx-0">
            <ToastContainer />
            <div className="row px-0 mx-0 d-flex">
              <div className="col-md-12 mx-0 px-0">
                <Header />
                <div className="row px-2 mx-0">
                  <div
                    className="px-0 my-2"
                    style={{ backgroundColor: "white", borderRadius: "8px" }}
                  >
                    <div
                      className="card-container-test mx-0 mt-2 px-2 pb-3 "
                      style={{ maxheight: "100" }}
                    >
                      <div className="px-0 d-flex justify-content-between">
                        <div className="d-flex mb-2">
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              value={searchInputValue}
                              onChange={handleInputChange}
                              style={{
                                width: "25vw",
                                borderRadius: "5px",
                              }}
                            />
                            {searchInputValue && (
                              <FontAwesomeIcon
                                icon={faXmark}
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "30%",
                                  color: "gray",
                                }}
                                onClick={() => {
                                  setsearchTest("");
                                  setSearchInputValue("");
                                }}
                              />
                            )}
                          </div>

                          <Button
                            variant="primary"
                            onClick={handleSearch}
                            className="btns mx-1"
                          >
                            <FontAwesomeIcon icon={faSearch} />
                          </Button>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "auto",
                          }}
                        >
                          <label
                            htmlFor="organizationName"
                            style={{
                              marginRight: "10px",
                              fontWeight: "bold",
                              marginLeft: "20px",
                            }}
                          >
                            Organization:
                          </label>
                          <Select
                            defaultValue={
                              selectedDropdownOuId
                                ? {
                                    value: selectedDropdownOuId,
                                    label:
                                      ouOptions.find(
                                        (ou) => ou.id === selectedDropdownOuId
                                      )?.ouName || "All",
                                  }
                                : { value: null, label: "All" }
                            }
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: "150px",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                width: "150px",
                                zIndex: "999",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected
                                  ? "transparent"
                                  : provided.background,
                                color: state.isSelected ? "black" : "black",
                              }),
                            }}
                            options={[
                              { value: null, label: "All" },
                              ...ouOptions.map((ou) => ({
                                value: ou.id,
                                label: ou.ouName,
                              })),
                            ]}
                            placeholder="Select"
                            isSearchable
                            onChange={(selectedOption) => {
                              if (selectedOption.value === null) {
                                setSelectedDropdownOuId(null);
                              } else {
                                setSelectedDropdownOuId(selectedOption.value);
                              }
                            }}
                          />
                        </div>

                        <div className="justify-content-end">
                          <Button
                            className="mx-1 mb-2 btns"
                            variant="primary"
                            onClick={handleCreateButtonClick}
                          >
                            Assign
                          </Button>
                          <Button
                            className="mx-1 mb-2 btns"
                            variant="primary"
                            onClick={toViewProperties}
                          >
                            View Properites
                          </Button>
                          <Button
                            variant="primary"
                            className="mx-1 mb-2 btns"
                            onClick={exportOuPropertyList}
                            disabled={ouPropertyData.length === 0}
                          >
                            Export
                          </Button>
                        </div>
                      </div>

                      <div className="employee-table">
                        <div
                          className="table-container"
                          style={{
                            maxHeight: "70vh",
                            overflow: "auto",
                          }}
                        >
                          <table>
                            <thead className="fixed-header">
                              <tr>
                                <th>Sr. No</th>
                                <th
                                  className="text-left"
                                  onClick={() => handleouPropertySort("ouName")}
                                >
                                  Organization Name&nbsp;
                                  {ouPropertySorting === "ouName" && (
                                    <FontAwesomeIcon
                                      icon={
                                        ouPropertyReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleouPropertySort("propertyName")
                                  }
                                >
                                  Property Name&nbsp;
                                  {ouPropertySorting === "propertyName" && (
                                    <FontAwesomeIcon
                                      icon={
                                        ouPropertyReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}
                                </th>
                                <th className="text-left">Property Value</th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleouPropertySort("createdDate")
                                  }
                                >
                                  Created Date&nbsp;
                                  {ouPropertySorting === "createdDate" && (
                                    <FontAwesomeIcon
                                      icon={
                                        ouPropertyReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleouPropertySort("updatedDate")
                                  }
                                >
                                  Updated Date&nbsp;
                                  {ouPropertySorting === "updatedDate" && (
                                    <FontAwesomeIcon
                                      icon={
                                        ouPropertyReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}
                                </th>

                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {ouPropertyData.map((property, index) => (
                                <tr
                                  className="clickable-row"
                                  key={property.id}
                                  style={{
                                    borderBottom: "1px solid #ddd",
                                    backgroundColor: property.isHighlight
                                      ? "#f5f5a5"
                                      : "transparent",
                                  }}
                                >
                                  <td>{startingSerialNumber + index}</td>

                                  <td className="text-left">
                                    {property.ouMaster?.ouName || "-"}
                                  </td>

                                  <td className="text-left">
                                    {property.propertyMaster?.propertyName ||
                                      "-"}
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{
                                      wordWrap: "break-word",
                                      maxWidth: "350px",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {property.propertyValue || "-"}
                                  </td>

                                  <td className="text-left">
                                    {new Date(
                                      property.createdDate
                                    ).toLocaleDateString("en-GB")}
                                  </td>

                                  <td className="text-left">
                                    {property.updatedDate
                                      ? new Date(
                                          property.updatedDate
                                        ).toLocaleDateString("en-GB")
                                      : "-"}
                                  </td>

                                  <td onClick={(e) => e.stopPropagation()}>
                                    <button
                                      style={{
                                        color: "black",
                                        border: "none",
                                      }}
                                      onClick={() =>
                                        handleUpdateClick(property)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faPen} />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        {!isLoadingListData && ouPropertyData.length === 0 && (
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "15px",
                            }}
                          >
                            <img src={Notfound} alt="Not Found" />
                            <p
                              style={{
                                marginTop: "-10px",
                                fontWeight: "bold",
                              }}
                            >
                              No Organization Property Found
                            </p>
                          </div>
                        )}
                      </div>

                      {!isLoadingListData && ouPropertyData.length > 0 && (
                        <div className="col-md-12 mt-3 d-flex justify-content-end">
                          <div className="pagination-buttons">
                            <div className="pagination-button">
                              <div
                                className={`circle ${
                                  currentPage === 1 ||
                                  ouPropertyData.length === 0
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() => handlePagination("prev")}
                              >
                                <FontAwesomeIcon icon={faChevronLeft} />
                              </div>
                            </div>

                            <div className="pagination-info">
                              <p>
                                {ouPropertyData.length > 0 ? rangeStart : 0} -{" "}
                                {ouPropertyData.length > 0 ? rangeEnd : 0} of{" "}
                                {ouPropertyData.length > 0
                                  ? ouPropertyCount
                                  : 0}{" "}
                              </p>
                            </div>

                            <div className="pagination-button">
                              <div
                                className={`circle ${
                                  currentPage === totalPages ||
                                  ouPropertyData.length === 0
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() => handlePagination("next")}
                              >
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <Modal
                        show={showUpdateForm}
                        onHide={handleCancleClick}
                        // dialogClassName="my-modal"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title className="text-center">
                            Update Organization Property
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {selectedOuProperty && (
                            <Form name="assignOuProperty">
                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "25px",
                                }}
                              >
                                {/* <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="propertyName"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Select Property
                                  </label>

                                  <Select
                                    value={
                                      selectedDropDownProperty
                                        ? {
                                            value: selectedDropDownProperty.id,
                                            label:
                                              selectedDropDownProperty.propertyName,
                                          }
                                        : {
                                            value: null,
                                            label: "Select Property",
                                          }
                                    }
                                    styles={{
                                      control: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                        zIndex: "999",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        background: state.isSelected
                                          ? "transparent"
                                          : provided.background,
                                        color: state.isSelected
                                          ? "black"
                                          : "black",
                                      }),
                                    }}
                                    options={[
                                      { value: null, label: "Select Property" },
                                      ...propertyMasterListDropDown.map(
                                        (property) => ({
                                          value: property.id,
                                          label: property.propertyName,
                                        })
                                      ),
                                    ]}
                                    placeholder="Select Property"
                                    isSearchable
                                    onChange={handleDropDownInputChange}
                                  />
                                  {selectedOuProperty.propertyMaster
                                    .propertyName && (
                                    <div
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {
                                        selectedOuProperty.propertyMaster
                                          .propertyName
                                      }
                                    </div>
                                  )}
                                </div> */}
                                {/* <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="propertyValue"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Property Value
                                  </label>
                                  {selectedOuProperty.propertyValue ===
                                    "true" ||
                                  selectedOuProperty.propertyValue ===
                                    "false" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ marginRight: "10px" }}>
                                        <input
                                          type="radio"
                                          id="propertyValueTrue"
                                          name="propertyValue"
                                          value="true"
                                          checked={
                                            assignPropertyFormData.propertyValue ===
                                            "true"
                                          }
                                          onChange={() =>
                                            setAssignPropertyFormData(
                                              (prev) => ({
                                                ...prev,
                                                propertyValue: "true",
                                              })
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor="propertyValueTrue"
                                          style={{ marginLeft: "5px" }}
                                        >
                                          True
                                        </label>
                                      </div>
                                      <div>
                                        <input
                                          type="radio"
                                          id="propertyValueFalse"
                                          name="propertyValue"
                                          value="false"
                                          checked={
                                            assignPropertyFormData.propertyValue ===
                                            "false"
                                          }
                                          onChange={() =>
                                            setAssignPropertyFormData(
                                              (prev) => ({
                                                ...prev,
                                                propertyValue: "false",
                                              })
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor="propertyValueFalse"
                                          style={{ marginLeft: "5px" }}
                                        >
                                          False
                                        </label>
                                      </div>
                                    </div>
                                  ) : (
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="propertyValue"
                                      id="propertyValue"
                                      value={
                                        assignPropertyFormData.propertyValue ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        setAssignPropertyFormData((prev) => ({
                                          ...prev,
                                          propertyValue: e.target.value,
                                        }))
                                      }
                                      placeholder="Enter Property Value"
                                    />
                                  )}
                                  {selectedOuProperty.propertyValue && (
                                    <div
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                        wordWrap: "break-word",
                                        wordBreak: "break-word",
                                        whiteSpace: "normal",
                                      }}
                                    >
                                      {selectedOuProperty.propertyValue}
                                    </div>
                                  )}
                                </div> */}

                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="propertyValue"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Property Value
                                  </label>
                                  {selectedOuProperty.propertyValue ===
                                    "true" ||
                                  selectedOuProperty.propertyValue ===
                                    "false" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ marginRight: "10px" }}>
                                        <input
                                          type="radio"
                                          id="propertyValueTrue"
                                          name="propertyValue"
                                          value="true"
                                          checked={
                                            assignPropertyFormData.propertyValue ===
                                            "true"
                                          }
                                          onChange={() =>
                                            setAssignPropertyFormData(
                                              (prev) => ({
                                                ...prev,
                                                propertyValue: "true",
                                              })
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor="propertyValueTrue"
                                          style={{ marginLeft: "5px" }}
                                        >
                                          True
                                        </label>
                                      </div>
                                      <div>
                                        <input
                                          type="radio"
                                          id="propertyValueFalse"
                                          name="propertyValue"
                                          value="false"
                                          checked={
                                            assignPropertyFormData.propertyValue ===
                                            "false"
                                          }
                                          onChange={() =>
                                            setAssignPropertyFormData(
                                              (prev) => ({
                                                ...prev,
                                                propertyValue: "false",
                                              })
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor="propertyValueFalse"
                                          style={{ marginLeft: "5px" }}
                                        >
                                          False
                                        </label>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="propertyValue"
                                        id="propertyValue"
                                        value={
                                          assignPropertyFormData.propertyValue ||
                                          ""
                                        }
                                        onChange={handlePropertyValueValidation}
                                        placeholder="Enter Property Value"
                                      />
                                      {error && (
                                        <div
                                          style={{
                                            color: "red",
                                            fontSize: "0.9rem",
                                            marginTop: "5px",
                                          }}
                                        >
                                          {error}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {selectedOuProperty.propertyValue && (
                                    <div
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                        wordWrap: "break-word",
                                        wordBreak: "break-word",
                                        whiteSpace: "normal",
                                      }}
                                    >
                                      {selectedOuProperty.propertyValue}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Form>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            className="btn btn-secondary"
                            onClick={(e) => handleCancleClick(e)}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="btn btn-primary"
                            onClick={(e) => handleAddClick(e)}
                            disabled={!isFormValid() || isLoading}
                          >
                            Update
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <Modal
                        show={showConfirmDialog}
                        onHide={handleCancelDilog}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <>Are you sure you want to Update this Property?</>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleCancelDilog}
                          >
                            Cancel
                          </Button>
                          <Button variant="primary" onClick={handleConfirm}>
                            Confirm
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      <Modal
                        show={showReConfirmDialog}
                        onHide={handleCancelDilogBox}
                        dialogClassName="my-modal"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Reconfirm Update</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {selectedOuProperty && (
                            <div>
                              <div
                                style={{
                                  marginBottom: "15px",
                                  fontSize: "1.2rem",
                                }}
                              >
                                Do you really want to apply these modifications
                                to the Property?
                              </div>
                              <table
                                className="table no-border"
                                style={{
                                  borderCollapse: "collapse",
                                  width: "100%",
                                  textAlign: "left",
                                  tableLayout: "fixed",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        textAlign: "left",
                                        width: "30%",
                                      }}
                                    >
                                      Field
                                    </th>
                                    <th
                                      style={{
                                        textAlign: "left",
                                        width: "35%",
                                      }}
                                    >
                                      Current
                                    </th>
                                    <th
                                      style={{
                                        textAlign: "left",
                                        width: "35%",
                                      }}
                                    >
                                      New
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* Property Name */}
                                  {selectedDropDownProperty && (
                                    <tr>
                                      <td style={{ textAlign: "left" }}>
                                        Property Name
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {
                                          selectedOuProperty.propertyMaster
                                            .propertyName
                                        }
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {selectedDropDownProperty.propertyName}
                                      </td>
                                    </tr>
                                  )}

                                  {/* {assignPropertyFormData.propertyValue && (
                                    <tr>
                                      <td style={{ textAlign: "left" }}>
                                        Property Value
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {selectedOuProperty.propertyValue}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {assignPropertyFormData.propertyValue}
                                      </td>
                                    </tr>
                                  )} */}

                                  {assignPropertyFormData.propertyValue && (
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          wordWrap: "break-word",
                                          whiteSpace: "normal",
                                        }}
                                      >
                                        Property Value
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          wordWrap: "break-word",
                                          whiteSpace: "normal",
                                        }}
                                      >
                                        {selectedOuProperty.propertyValue}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          wordWrap: "break-word",
                                          whiteSpace: "normal",
                                        }}
                                      >
                                        {assignPropertyFormData.propertyValue}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleCancelDilogBox}
                          >
                            Cancel
                          </Button>
                          <Button variant="primary" onClick={handleReConfirm} disabled={isLoading}>
                            Confirm
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      {/* success Dialog Box*/}
                      <Modal
                        show={isSuccessModalVisible}
                        onHide={handleCloseSuccessModal}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Success</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <>Organization property updated successfully..!</>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleCloseSuccessModal}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetAllOuPropertyList;
